import React from 'react';
import { TextInput, Filter, SelectInput, ReferenceInput } from 'react-admin';
import { roleChoices } from '../../../../config/user.config';

export const PostFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Phone" source="phone" alwaysOn />
      <TextInput label="Email" source="email" alwaysOn />
      <ReferenceInput
        label="ra.label.supplier"
        source="supplierId"
        reference="supplier"
        resettable
        alwaysOn
        perPage={300}
      >
        <SelectInput optionText="name" alwaysOn />
      </ReferenceInput>
      <SelectInput
        source="role"
        label="ra.label.role"
        choices={roleChoices}
        optionText="name"
        optionValue="id"
        alwaysOn
        resettable
      />
    </Filter>
  );
};
