import TenantCreate from './components/Create';
import TenantShow from './components/Show';
import TenantList from './components/List';
import TenantEdit from './components/Edit';

export default {
  list: TenantList,
  create: TenantCreate,
  edit: TenantEdit,
  show: TenantShow,
};
