import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import ServiceFeesCreate from './components/Create';
import ServiceFeesShow from './components/Show';
import ServiceFeesList from './components/List';
import ServiceFeesEdit from './components/Edit';

export default {
  list: ServiceFeesList,
  create: ServiceFeesCreate,
  edit: ServiceFeesEdit,
  show: ServiceFeesShow,
  icon: RequestQuoteOutlinedIcon,
};
