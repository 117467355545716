import React from 'react';
import {
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  useCreate,
  useRedirect,
} from 'react-admin';
import randomString from 'crypto-random-string';
import { TranslatableLongTextInput, TranslatableTextInput } from '../../../components/Translatable';
import { baseUrl } from '../../../config/connection';
import { ALL_USER_ROLES_NAMES } from '../../../config/user.config';
import ServingSizeInput from '../ServingSizeInput';
import S3FileInput from '../../../components/S3FileUploader/Input';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();
const tenantId = Number(localStorage.getItem('tenantId'));

const GoodsCreate = () => {
  const [create] = useCreate();
  const redirect = useRedirect();

  const supplierFromStorage = localStorage.getItem('supplierId');
  const roleFromLocalStorage = localStorage.getItem('role');
  const defaultFormRecord = { isActive: true };

  const formRecord =
    roleFromLocalStorage === ALL_USER_ROLES_NAMES.SUPPLIER_ADMIN
      ? { supplierId: supplierFromStorage, ...defaultFormRecord }
      : defaultFormRecord;

  const onSubmit = (values) => {
    create(
      'goods',
      {
        data: {
          ...values,
          ...formRecord,
          tenantId,
        },
      },
      {
        onSuccess: () => {
          redirect('list', 'goods');
        },
      },
    );
  };

  return (
    <Create
      redirect={(_, __, data) => {
        return `supplier/${data?.supplierId}/show/goods`;
      }}
      actions={<CancelButton />}
    >
      <SimpleForm onSubmit={onSubmit}>
        <TranslatableTextInput label="ra.label.name" source="name" validate={validateRequired} />
        <TranslatableLongTextInput fullWidth label="ra.label.description" source="description" />
        <NumberInput
          parse={(v) => v * 100 || ''}
          format={(v) => v / 100 || ''}
          label="ra.label.price"
          source="price"
          placeholder="Price"
          validate={validateRequired}
          inputProps={{
            step: 0.01,
            min: 0,
          }}
        />
        <ServingSizeInput source="servingData" />
        <ReferenceInput
          label="ra.label.category"
          source="categoryId"
          reference="category"
          perPage={5000}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            sx={{ maxWidth: '218px', width: '100%' }}
            validate={validateRequired}
          />
        </ReferenceInput>
        <TextInput label="ra.label.external" source="externalId" />
        <S3FileInput
          apiRoot={baseUrl}
          source="cover"
          validate={validateRequired}
          uploadOptions={{
            signingUrl: `${baseUrl}/s3/sign`,
            s3path: `dely/${randomString({ length: 10, type: 'alphanumeric' })}`,
            multiple: false,
            accept: 'image/*',
          }}
        />
      </SimpleForm>
    </Create>
  );
};

export default GoodsCreate;
