import React from 'react';
import {
  Edit,
  SimpleForm,
  useEditController,
  useRedirect,
  useUpdate,
} from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import UIHtmlSeoForm from '../common/Form';

const UIBannerEdit = () => {
  const editController = useEditController();
  const redirect = useRedirect();
  const [update] = useUpdate();

  const onSubmit = (data) => {
    update(
      'ui/html-seo',
      { id: editController.record.id, data, previousData: editController.record },
      {
        onSuccess: () => {
          redirect('list', 'ui/html-seo');
        },
      },
    );
  };

  return (
    <Edit actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />} onSubmit={onSubmit}>
        <UIHtmlSeoForm />
      </SimpleForm>
    </Edit>
  );
};

export default UIBannerEdit;
