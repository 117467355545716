import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  subgroup: {
    paddingBottom: 12,
    backgroundColor: 'transparent',
    border: 'none',
    '&::before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  summary: {
    height: 40,
    background: '#F5F5F9',
    fontWeight: 'bold',
    fontSize: 14,
    minHeight: 'auto',
    color: '#666',
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
  },
  expandDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0 10px 0px',
  },
});

export default useStyles;
