import React, { useEffect, useState } from 'react';
import { Edit, SelectInput, SimpleForm, TextInput, minValue, regex, required, useEditController, useRedirect, useUpdate } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import PhoneInput from '../../../components/PhoneInput';
import ResourceTitle from '../../../components/ResourceTitle';
import { baseUrl } from '../../../config/connection';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';

const validateRequired = required();
const validateMinValue = [minValue(25)];
const ssnRegex = regex(
  /^(19|20)?[0-9]{6}[-+]?[0-9]{4}$|^(19|20)?[0-9]{10}$/,
  'Invalid SSN, SSN should be 10 or 12 digits long without "-" character!',
);
const validateSSN = [validateRequired, ssnRegex];

const CourierEdit = () => {
  const [easyFreelanceFlag, setEasyFreelanceFlag] = useState(false);

  const editController = useEditController();
  const redirect = useRedirect();
  const [update] = useUpdate();

  const onSubmit = (data) => {
    update(
      'courier',
      { id: editController.record.id, data, previousData: editController.record },
      {
        onSuccess: () => {
          redirect('list', 'courier');
        },
      },
    );
  };

  const fetchFeatureFlag = (flag) => {
    fetchWithAuthorization(`${baseUrl}/feature-flags/${flag}`)
      .then((results) => results.json())
      .then((data) => setEasyFreelanceFlag(data));
  };

  useEffect(() => {
    fetchFeatureFlag('integrations.easy-freelance');
  });

  return (
    <Edit title={<ResourceTitle field="firstName" />} actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />} onSubmit={onSubmit}>
        <TextInput label="ra.label.email" source="email" validate={validateRequired} />
        <TextInput label="ra.label.password" source="password" />
        <PhoneInput source="phone" />
        <TextInput label="ra.label.first_name" source="firstName" validate={validateRequired} />
        <TextInput label="ra.label.last_name" source="lastName" validate={validateRequired} />
        <SelectInput
          source="status"
          choices={[
            { id: 'ONLINE', name: 'Online' },
            { id: 'OFFLINE', name: 'Offline' },
          ]}
        />

        {/* Easy freelance user details */}
        {easyFreelanceFlag &&
          <>
            <TextInput
              label="ra.label.ssn"
              source="easyFreelanceUser.ssn"
              validate={validateRequired}
            />
            <TextInput
              label="ra.label.address"
              source="easyFreelanceUser.address"
              validate={validateRequired}
            />
            <TextInput
              label="ra.label.city"
              source="easyFreelanceUser.city"
              validate={validateRequired}
            />
            <TextInput
              label="ra.label.postal_code"
              source="easyFreelanceUser.postal_code"
              validate={validateRequired}
            />
            <SelectInput
              label="ra.label.main_work"
              source="easyFreelanceUser.main_work"
              choices={[
                { id: 'false', name: 'FALSE' },
                { id: 'true', name: 'TRUE' },
              ]}
              sx={{ width: '206px' }}
            />
            <TextInput label="ra.label.nationality" source="easyFreelanceUser.nationality" />
            <TextInput label="ra.label.note" source="easyFreelanceUser.note" />
            <TextInput
              label="ra.label.bank_clearing_number"
              source="easyFreelanceUser.bank_clearing_number"
            />
            <TextInput
              label="ra.label.bank_account_number"
              source="easyFreelanceUser.bank_account_number"
            />
            <TextInput
              label="ra.label.tax_percent"
              source="easyFreelanceUser.tax_percent"
              validate={validateMinValue}
            />
          </>}
      </SimpleForm>
    </Edit>
  );
};

export default CourierEdit;
