import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import TagsCreate from './components/Create';
import TagsShow from './components/Show';
import TagsList from './components/List';
import TagsEdit from './components/Edit';

export default {
  list: TagsList,
  create: TagsCreate,
  edit: TagsEdit,
  show: TagsShow,
  icon: LocalOfferOutlinedIcon,
};
