import React from 'react';
import { List, Datagrid, TextField, ChipField, FunctionField } from 'react-admin';
import {
  ALL_USER_ROLES_IDS as roleIds,
  ALL_USER_ROLES_NAMES as roleNames,
} from '../../../../config/user.config';
import { PostFilter } from '../PostFilter';
import ListDeleteConfirmationButton from '../../../../components/ListDeleteConfirmationButton';
import { ListTopToolBar } from '../../../../components/ListTopToolBar';
import { formatPhone } from '../../../../utils/formatPhone';

const UserList = (props) => {
  const supplierId = localStorage.getItem('supplierId');
  const role = localStorage.getItem('role');
  const filter =
    role === roleNames.ADMIN ? {} : { filter: { supplierId, role: roleIds.SUPPLIER_BRANCH } };

  return (
    <List {...props} {...filter} filters={<PostFilter />} actions={<ListTopToolBar />}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField source="id" />
        <TextField source="email" />
        <FunctionField
          source="phone"
          render={({ phone }) => formatPhone(phone)}
        />
        <ChipField color="secondary" source="roleName" />
      </Datagrid>
    </List>
  );
};

export default UserList;
