import React from 'react';
import { Tab, Show, TextField, ChipField, TabbedShowLayout } from 'react-admin';
import ResourceTitle from '../../../../components/ResourceTitle';

const TenantAdminsShow = (props) => {
  return (
    <Show title={<ResourceTitle field="username" />} {...props}>
      <TabbedShowLayout>
        <Tab label="ra.tab.summary">
          <TextField label="ra.label.id" source="id" />
          <TextField label="ra.label.email" source="email" />
          <TextField label="ra.label.first_name" source="firstName" />
          <TextField label="ra.label.last_name" source="lastName" />
          <ChipField
            sx={{ width: '180px' }}
            label="ra.label.role"
            color="secondary"
            source="roleName"
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default TenantAdminsShow;
