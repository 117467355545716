import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import UIStaticPagesCreate from './components/Create';
import UIStaticPagesEdit from './components/Edit';
import UIStaticPagesList from './components/List';
import UIStaticPagesShow from './components/Show';

export default {
  list: UIStaticPagesList,
  create: UIStaticPagesCreate,
  show: UIStaticPagesShow,
  edit: UIStaticPagesEdit,
  icon: InsertDriveFileOutlinedIcon,
};
