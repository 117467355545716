import React, { useState } from 'react';
import { CardActions } from '@mui/material';
import {
  EditButton,
  Button,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  useNotify,
  useRefresh,
} from 'react-admin';

import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconContentAdd from '@mui/icons-material/Add';
import { postWithAuthorization } from '../../../../utils/fetchWithAuthorization';
import url from '../../../../config/connection';

import useStyles from './styles';
import DiscountActionToolbar from '../../../../components/DiscountActionToolbar';

const ShowActions = ({ basePath, data }) => {
  const [open, setOpen] = useState(false);
  const styles = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const giveDiscount = ({ values }) => {
    if (values.data && values.discountId) {
      postWithAuthorization(`${url}/owned-discount`, {
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          owner: {
            type: values.roleName,
            id: String(values.id),
          },

          discountId: values.discountId,
          validUntil: new Date(values.data),
        }),
      })
        .then(() => {
          notify('Approved');
        })
        .catch((e) => {
          notify('Error: Not approved', { type: 'warning' });
        });
    }
  };

  return (
    <CardActions className={styles.root}>
      <EditButton record={data} />

      <Button color="primary" onClick={handleOpen} label="ra.action.give_bonus">
        <IconContentAdd />
      </Button>

      <Modal
        disableEnforceFocus
        className={styles.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 1500,
        }}
      >
        <Card>
          <CardContent>
            <SimpleForm
              onSubmit={(values) => {
                giveDiscount({ values });
                handleClose();
                refresh();
              }}
              toolbar={<DiscountActionToolbar />}
            >
              <ReferenceInput label="ra.label.discount" reference="discount" source="discountId">
                <SelectInput optionText="name" sx={{ maxWidth: '205px', width: '100%' }} />
              </ReferenceInput>
              <DateTimeInput source="data" label="ra.label.data" />
            </SimpleForm>
          </CardContent>
        </Card>
      </Modal>
    </CardActions>
  );
};

export default ShowActions;
