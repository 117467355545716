import React, { useState, useEffect } from 'react';
import {
  Create,
  FormTab,
  NumberInput,
  required,
  TabbedForm,
  TextInput,
  useCreate,
  useRedirect,
  useGetOne,
  useCreateController,
  useResourceContext,
} from 'react-admin';
import GoogleMap from 'google-map-react';
import { useForm, useWatch } from 'react-hook-form';
import { ScheduleInput } from '../../../components/Schedule';
import { DEFAULT_GEO, DEFAULT_ZOOM, GOOGLE_MAPS_API_KEY } from '../../../config/maps.config';
import ClientPlacemark from '../../../components/maps/ClientPlacemark';
import SupplierAutocomplete from '../../../components/SupplierAutocomplete';
import PhoneInput from '../../../components/PhoneInput';
import CancelButton from '../../../components/CancelButton';
import { SUPPLIER_PAYOUT } from '../../../config/supplier.config';

const validateRequired = required();
const defaultGeo = { lat: DEFAULT_GEO[0], lng: DEFAULT_GEO[1] };
const tenantId = Number(localStorage.getItem('tenantId'));

const AddressCreate = (props) => {
  const createController = useCreateController();
  const { data: supplier } = useGetOne('supplier', { id: createController?.record?.supplierId });

  const redirect = useRedirect();
  const resource = useResourceContext();
  const [create] = useCreate();

  const [financialTabOpen, setFinancialTabOpen] = useState(false);

  const { control, setValue } = useForm({
    defaultValues: {
      geo: defaultGeo,
    },
  });

  const values = useWatch({
    control,
  });

  const setGeoForm = (newGeo) => {
    setValue('geo', newGeo);
  };

  const handleCreate = (data) => {
    const dataWithGeo = { ...data, geo: values.geo, tenantId };
    create(
      resource,
      { data: dataWithGeo },
      {
        onSuccess: () => {
          redirect('list', `/supplier/${data.supplierId}/show/addresses`);
        },
      },
    );
  };

  useEffect(() => {
    if (supplier) setFinancialTabOpen(supplier.payout === SUPPLIER_PAYOUT.BRANCH);
  }, [supplier]);

  return (
    <Create {...props} actions={<CancelButton />}>
      <TabbedForm onSubmit={handleCreate}>
        <FormTab label="ra.tab.general">
          <TextInput label="ra.label.name" source="name" validate={validateRequired} />
          <TextInput label="ra.label.notes" source="notes" />
          <PhoneInput source="phoneNumber" shouldValidate />
          <TextInput label="ra.label.external" source="externalId" />
          <SupplierAutocomplete source="supplierId" validate />
          <ScheduleInput source="schedule" setValue={setValue} />
          <div style={{ display: 'flex', flex: '1', flexWrap: 'wrap', gap: 16 }}>
            <TextInput label="ra.label.country" source="country" validate={validateRequired} />
            <TextInput label="ra.label.area" source="area" validate={validateRequired} />
            <TextInput label="ra.label.locality" source="locality" validate={validateRequired} />
            <TextInput label="ra.label.street" source="street" validate={validateRequired} />
            <TextInput label="ra.label.house" source="house" validate={validateRequired} />
          </div>
          <div style={{ display: 'flex', flex: '1', flexWrap: 'wrap', gap: 16 }}>
            <NumberInput
              label="Lat"
              source="geo.lat"
              validate={validateRequired}
              control={control}
            />
            <NumberInput
              label="Lng"
              source="geo.lng"
              validate={validateRequired}
              control={control}
            />
          </div>

          <div style={{ height: '700px', width: '100%' }}>
            <GoogleMap
              bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
              center={values.geo || defaultGeo}
              defaultZoom={DEFAULT_ZOOM}
              onClick={({ lat, lng }) => {
                const geo = { lat, lng };
                setGeoForm(geo);
              }}
              control={control}
            >
              <ClientPlacemark {...values.geo} />
            </GoogleMap>
          </div>
        </FormTab>
        {
          financialTabOpen && (
            <FormTab label="ra.tab.financial">
              <TextInput label="ra.label.email" source="email" />
              <TextInput
                source="bankAccount"
                label="ra.label.bank_account_number"
              />
              <TextInput label="CVR" source="cvrnumber" />
            </FormTab>
          )
        }
      </TabbedForm>
    </Create>
  );
};

export default AddressCreate;
