import React, { useState } from 'react';
import {
  Create,
  required,
  SelectInput,
  TextInput,
  useCreate,
  useRedirect,
  SimpleForm,
} from 'react-admin';
import Editor from '@monaco-editor/react';
import randomString from 'crypto-random-string';
import { Box, Tab, Tabs } from '@mui/material';
import { useWatch, useForm } from 'react-hook-form';
import S3FileInput from '../../../components/S3FileUploader/Input';
import { baseUrl } from '../../../config/connection';
import { useOrganizationLocales } from '../../../hooks/useOrganizationLocales';
import { a11yProps } from '../../../utils/a11yProps';
import { TabPanel } from '../../../components/MuiTabPanel';
import CancelButton from '../../../components/CancelButton';
import { BannerType } from '../constants';

const validateRequired = required();
const tenantId = Number(localStorage.getItem('tenantId'));

const Label = ({ children }) => (
  <span style={{ margin: '0 0 15px 0', display: 'block' }}>{children}</span>
);

const UIBannerCreate = () => {
  const locales = useOrganizationLocales();
  const [currentLocale, setCurrentLocale] = useState('en');

  const [create] = useCreate();
  const redirect = useRedirect();

  const { control } = useForm();
  const bannerType = useWatch({ name: 'bannerType', control });

  const [htmlDesktopByLocale, setHtmlDesktopByLocale] = useState({});
  const [htmlMobileByLocale, setHtmlMobileByLocale] = useState({});

  const activeHtmlDesktopByLocale = htmlDesktopByLocale[currentLocale] ?? '';
  const activeHtmlMobileByLocale = htmlMobileByLocale[currentLocale] ?? '';

  const handleChangeLocale = (_event, newValue) => {
    setCurrentLocale(newValue);
  };

  const onSubmit = async (data) => {
    const dataWithHtml = {
      ...data,
      tenantId,
      bannerType,
      htmlDesktop: htmlDesktopByLocale,
      htmlMobile: htmlMobileByLocale,
    };

    create(
      'ui/banner',
      { data: dataWithHtml },
      {
        onSuccess: () => {
          redirect('list', 'ui/banner');
        },
      },
    );
  };

  return (
    <Create redirect="list" actions={<CancelButton />}>
      <SimpleForm onSubmit={onSubmit}>
        <TextInput label="ra.label.title" source="title" resettable validate={validateRequired} />
        <TextInput label="ra.label.redirect_url" source="redirectUrl" resettable />
        <SelectInput
          validate={validateRequired}
          source="bannerType"
          label="ra.label.banner.type"
          control={control}
          choices={[
            { id: BannerType.HTML, name: 'HTML' },
            { id: BannerType.Image, name: 'Image' },
          ]}
        />

        {bannerType === BannerType.HTML && (
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '60dvw' }}>
            <p style={{ color: 'gray', fontSize: 13 }}>
              Warning: Content limit is 50000 characters. Longer content will cause an error.
            </p>
            <TabPanel value={currentLocale} index={currentLocale}>
              <Tabs value={currentLocale} onChange={handleChangeLocale} aria-label="locales tabs">
                {locales.map((locale, idx) => {
                  return (
                    <Tab
                      value={locale}
                      label={locale}
                      key={locale}
                      sx={{ width: '125px' }}
                      {...a11yProps(idx)}
                    />
                  );
                })}
              </Tabs>
              <Box sx={{ borderTop: '1px solid black' }}>
                <Label>Banner Desktop</Label>
                <Editor
                  value={activeHtmlDesktopByLocale}
                  language="html"
                  key={`html-desktop-${currentLocale}`}
                  height={300}
                  onChange={(value) => {
                    setHtmlDesktopByLocale((localeDefaults) => ({
                      ...localeDefaults,
                      [currentLocale]: value,
                    }));
                  }}
                />
              </Box>
              <Box sx={{ borderTop: '1px solid black' }}>
                <Label>Banner Mobile</Label>
                <Editor
                  value={activeHtmlMobileByLocale}
                  language="html"
                  key={`html-mobile-${currentLocale}`}
                  height={300}
                  onChange={(value) => {
                    setHtmlMobileByLocale((localeDefaults) => ({
                      ...localeDefaults,
                      [currentLocale]: value,
                    }));
                  }}
                />
              </Box>
            </TabPanel>
          </Box>
        )}

        {bannerType === BannerType.Image &&
          locales.map((locale) => (
            <Box
              key={`image-${locale}`}
              sx={{
                border: '1px solid black',
                marginBottom: '32px',
                padding: '0 16px',
                width: '70dvw',
              }}
            >
              <h3>{locale.toUpperCase()}</h3>
              <Label>Banner Desktop</Label>
              <S3FileInput
                apiRoot={baseUrl}
                source={`imageDesktop.${locale}`}
                validate={validateRequired}
                uploadOptions={{
                  id: `imageDesktop-${locale}`,
                  signingUrl: `${baseUrl}/s3/sign`,
                  s3path: `dely/${randomString({ length: 10, type: 'alphanumeric' })}`,
                  multiple: false,
                  accept: 'image/*',
                }}
                fileCoverImg="http://www.sclance.com/pngs/file-png/file_png_487794.jpg"
              />
              <Label>Banner Mobile</Label>
              <S3FileInput
                apiRoot={baseUrl}
                source={`imageMobile.${locale}`}
                validate={validateRequired}
                uploadOptions={{
                  id: `imageMobile-${locale}`,
                  signingUrl: `${baseUrl}/s3/sign`,
                  s3path: `dely/${randomString({ length: 10, type: 'alphanumeric' })}`,
                  multiple: false,
                  accept: 'image/*',
                }}
                fileCoverImg="http://www.sclance.com/pngs/file-png/file_png_487794.jpg"
              />
            </Box>
          ))}
      </SimpleForm>
    </Create>
  );
};

export default UIBannerCreate;
