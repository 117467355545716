import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import UserList from './components/List';
import UserShow from './components/Show';
import UserEdit from './components/Edit';
import UserCreate from './components/Create';

export default {
  list: UserList,
  show: UserShow,
  create: UserCreate,
  edit: UserEdit,
  icon: GroupOutlinedIcon,
};
