import CircleNotificationsOutlinedIcon from '@mui/icons-material/CircleNotificationsOutlined';
import NotificationCreate from './components/Create';
import NotificationEdit from './components/Edit';
import NotificationList from './components/List';
import NotificationShow from './components/Show';

export default {
  create: NotificationCreate,
  edit: NotificationEdit,
  list: NotificationList,
  show: NotificationShow,
  icon: CircleNotificationsOutlinedIcon,
};
