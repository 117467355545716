import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import PromoCodeShow from './components/Show';
import PromocodeEdit from './components/Edit';
import PromocodeList from './components/List';
import PromocodeCreate from './components/Create';

export default {
  list: PromocodeList,
  create: PromocodeCreate,
  edit: PromocodeEdit,
  show: PromoCodeShow,
  icon: FormatListNumberedOutlinedIcon,
};
