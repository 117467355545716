import React, { memo, useEffect, useMemo, useState } from 'react';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  usePermissions,
  AutocompleteInput,
  ReferenceField,
  useCreate,
  useRedirect,
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import { ALL_USER_ROLES_NAMES } from '../config/user.config';
import MenuEdit from './MenuEdit';
import ActivationCheckbox from './ActivationCheckbox';
import styles from './styles';
import { fetchWithAuthorization } from '../utils/fetchWithAuthorization';
import { baseUrl } from '../config/connection';
import { ListTopToolBar } from '../components/ListTopToolBar';
import SupplierFilter from '../components/SupplierFilter';
import CancelButton from '../components/CancelButton';

const validateRequired = required();
const tenantId = Number(localStorage.getItem('tenantId'));

const SupplierAutocomplete = memo((props) => {
  const [choices, setChoices] = useState([]);
  useEffect(() => {
    fetchWithAuthorization(`${baseUrl}/admin/supplier`)
      .then((res) => res.json())
      .then((res) => setChoices(res));
  }, []);

  return (
    <AutocompleteInput
      optionText="name"
      optionValue="id"
      choices={[
        {
          id: null,
          name: 'ALL',
        },
        ...choices,
      ]}
      {...props}
    />
  );
});

const useStyles = makeStyles({
  tableMain: () => ({
    '& .column-isActive': {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
});

const MenuList = () => {
  const isSuperAdmin = localStorage.getItem('role') === ALL_USER_ROLES_NAMES.ADMIN;
  const localSupplierId = localStorage.getItem('supplierId');
  const constantFilter = isSuperAdmin ? {} : { supplierId: localSupplierId };
  const classes = useStyles();

  return (
    <List
      filters={<SupplierFilter />}
      filter={constantFilter}
      perPage={25}
      actions={<ListTopToolBar />}
    >
      <Datagrid className={classes.tableMain} rowClick="show" bulkActionButtons={false}>
        <TextField label="ra.label.name" source="name" />
        <ReferenceField
          label="ra.label.supplier"
          source="supplierId"
          reference="supplier"
        >
          <TextField source="name" />
        </ReferenceField>
        <ActivationCheckbox label="ra.label.active" source="isActive" />
      </Datagrid>
    </List>
  );
};

const MenuCreate = () => {
  const { permissions } = usePermissions();
  const isAdmin = permissions === ALL_USER_ROLES_NAMES.ADMIN;
  const supplierId = useMemo(() => localStorage.getItem('supplierId'), []);

  const [create] = useCreate();
  const redirect = useRedirect();

  const onSubmit = (values) => {
    create(
      'menu',
      {
        data: {
          ...values,
          tenantId,
          supplierId,
        },
      },
      {
        onSuccess: () => {
          redirect('list', 'menu');
        },
      },
    );
  };

  return (
    <Create redirect="list" actions={<CancelButton />}>
      <SimpleForm defaultValue={{ supplierId }} onSubmit={onSubmit}>
        <TextInput validate={validateRequired} label="ra.label.name" source="name" />
        {!isAdmin ? (
          <NumberInput label="ra.label.supplier" source="supplierId" style={styles.hidden} />
        ) : (
          <SupplierAutocomplete
            label="ra.label.supplier"
            source="supplierId"
            validate={validateRequired}
            sx={{ maxWidth: '218px', width: '100%' }}
          />
        )}
      </SimpleForm>
    </Create>
  );
};

export default {
  list: MenuList,
  create: MenuCreate,
  edit: MenuEdit,
  icon: MenuBookOutlinedIcon,
};
