import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DeliverySettingsCreate from './components/Create';
import DeliverySettingsEdit from './components/Edit';
import DeliverySettingsList from './components/List';
import DeliverySettingsShow from './components/Show';

export default {
  create: DeliverySettingsCreate,
  edit: DeliverySettingsEdit,
  show: DeliverySettingsShow,
  list: DeliverySettingsList,
  icon: SettingsOutlinedIcon,
};
