import React, { useState } from 'react';
import {
  SimpleForm,
  Edit,
  required,
  NumberInput,
  BooleanInput,
  FormDataConsumer,
  TextInput,
  number,
  useEditController,
  useUpdate,
  useRedirect,
} from 'react-admin';
import { useForm, useWatch } from 'react-hook-form';
import ResourceTitle from '../../../components/ResourceTitle';
import SupplierAutocomplete from '../../../components/SupplierAutocomplete';
import { TranslatableTextInput } from '../../../components/Translatable';
import { isSuperAdmin } from '../../../utils/roles';

import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';
import { translateUpdateHelper } from '../../../components/Translatable/helper';

const validateRequired = required();
const validateStock = [validateRequired, number()];

const OptionFormDataConsumer = ({ formData, control, ...rest }) => {
  const [isFreeOption, setIsFreeOption] = useState(formData.isFreeOption);

  const handlePriceChange = (e) => {
    setIsFreeOption(e.target.checked);
  };

  return (
    <>
      <BooleanInput label="ra.label.available" source="isActive" />
      <BooleanInput
        label="ra.label.is_free_option"
        source="isFreeOption"
        onChange={(e) => handlePriceChange(e)}
        {...rest}
      />
      <TranslatableTextInput
        label="ra.label.name"
        source="name"
        validate={validateRequired}
        control={control}
      />
      <div
        style={{
          display: 'flex',
          gap: '1rem',
        }}
      >
        <NumberInput
          parse={(v) => v * 100}
          format={(v) => v / 100}
          label="ra.label.price"
          source="price"
          validate={validateStock}
          {...rest}
        />
        <NumberInput
          label="ra.label.maxQuantity"
          source="maxOptions"
          min={0}
          validate={validateStock}
          {...rest}
        />
        {isFreeOption && (
          <>
            <NumberInput
              label="ra.label.maxFreeQuantity"
              source="maxFreeOptions"
              min={0}
              validate={validateStock}
              {...rest}
            />
            <p
              style={{
                color: 'red',
                fontSize: '12px',
              }}
            >
              *Price applies after max free quantity is reached
            </p>
          </>
        )}
      </div>
      {isSuperAdmin() && <SupplierAutocomplete />}
      <TextInput label="ra.label.external" source="externalId" />
    </>
  );
};

const OptionEdit = () => {
  const editController = useEditController();
  const { control } = useForm();
  const [update] = useUpdate();
  const redirect = useRedirect();

  const translationValues = useWatch({
    control,
    defaultValue: {
      traslations: editController?.record?.translations,
    },
  });

  const onSubmit = (data) => {
    translateUpdateHelper(editController.record.translations, data, translationValues);

    update(
      'option',
      { id: editController.record.id, data, previousData: editController.record },
      {
        onSuccess: () => {
          redirect('list', 'option');
        },
      },
    );
  };

  return (
    <Edit title={<ResourceTitle field="name" />} actions={<CancelButton />} redirect="/option">
      <SimpleForm toolbar={<EditDeleteConfirmationButton />} onSubmit={onSubmit}>
        <FormDataConsumer>
          {(formDataProps) => {
            return <OptionFormDataConsumer {...formDataProps} control={control} />;
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default OptionEdit;
