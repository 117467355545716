/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  required,
  Button,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';
import randomString from 'crypto-random-string';
import EditIcon from '@mui/icons-material/Edit';
import IconCancel from '@mui/icons-material/Cancel';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button as ButtonMui,
} from '@mui/material';
import { useController, useForm, useWatch } from 'react-hook-form';
import TranslatableLabel from '../../../components/Translatable/TranslatableFields/TranslatableLabel';
import { AddImage } from '../styles';
import { baseUrl } from '../../../config/connection';
import S3FileInput from '../../../components/S3FileUploader/Input';

const validateRequired = required();

const AddNewItem = (props) => {
  const { index, listItem, saveItem } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const { control, reset } = useForm();

  const values = useWatch({
    control,
    defaultValue: listItem,
  });

  const { field: slug } = useController({
    name: 'slug',
    control,
    defaultValue: listItem?.slug,
  });
  const { field: action } = useController({
    name: 'action',
    control,
    defaultValue: listItem?.action?.type,
  });
  const { field: tags } = useController({
    name: 'tags',
    control,
    defaultValue: listItem?.action?.tags,
  });
  const { field: image } = useController({
    name: 'image',
    control,
    defaultValue: listItem?.image,
  });

  useEffect(() => {
    setIsFormValid(true);
    if (!values?.slug) {
      setIsFormValid(false);
    }
  }, [values]);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
    reset();
  };

  const handleSubmit = () => {
    saveItem(index, values);
    reset();
    setShowDialog(false);
  };

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={handleClick}
        aria-label="close"
        sx={{ zIndex: 99999 }}
      >
        <EditIcon />
      </IconButton>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
        closeAfterTransition
        disableEnforceFocus
      >
        <DialogTitle>Edit item</DialogTitle>
        <DialogContent>
          <SimpleForm resource="posts" toolbar={null}>
            <TextInput
              source="slug"
              {...slug}
              validate={validateRequired}
              control={control}
              sx={{ maxWidth: '270px', width: '100%' }}
            />
            <TranslatableLabel label="ra.label.photo" {...props} />
            <AddImage>
              <S3FileInput
                apiRoot={baseUrl}
                control={control}
                source="image"
                uploadOptions={{
                  signingUrl: `${baseUrl}/s3/sign`,
                  s3path: `dely/${randomString({ length: 10, type: 'alphanumeric' })}`,
                  multiple: false,
                  accept: 'image/*',
                }}
                fileCoverImg="http://www.sclance.com/pngs/file-png/file_png_487794.jpg"
              />
            </AddImage>
            <SelectInput
              source="action"
              choices={[{ id: 'FILTER', name: 'FILTER' }]}
              control={control}
              validate={validateRequired}
              sx={{ maxWidth: '270px', width: '100%' }}
              {...action}
            />
            <ReferenceArrayInput source="tags" reference="tag">
              <SelectArrayInput
                optionText="name"
                optionValue="slug"
                label="resources.tag.name"
                control={control}
                sx={{ maxWidth: '270px', width: '100%' }}
                {...tags}
              />
            </ReferenceArrayInput>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <ButtonMui onClick={handleSubmit} disabled={!isFormValid}>
            Save
          </ButtonMui>
          <Button label="ra.action.cancel" onClick={handleCloseClick}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddNewItem;
