import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  useEditController,
  useRedirect,
  useUpdate,
} from 'react-admin';
import {
  MARKETING_CAMPAIGN_ACTION_DEFAULT_VALUES,
  MARKETING_CAMPAIGN_ACTION_TYPE,
  MARKETING_CAMPAIGN_SOURCE_TYPE,
} from '../../utils/constants';

import useStyles from '../../style';
import CancelButton from '../../../../components/CancelButton';
import EditDeleteConfirmationButton from '../../../../components/EditDeleteConfirmationButton';

const validateRequired = required();

const MarketingCampaignEdit = (props) => {
  const styles = useStyles();

  const editController = useEditController();
  const redirect = useRedirect();
  const [update] = useUpdate();

  const onSubmit = (data) => {
    update(
      'marketing-campaigns',
      { id: editController.record.id, data, previousData: editController.record },
      {
        onSuccess: () => {
          redirect('list', 'marketing-campaigns');
        },
      },
    );
  };

  return (
    <Edit {...props} actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />} onSubmit={onSubmit}>
        <TextInput label="ra.label.name" source="name" validate={validateRequired} />
        <SelectInput
          label="ra.label.marketing_campaign_source_type"
          source="source.type"
          choices={MARKETING_CAMPAIGN_SOURCE_TYPE}
          validate={validateRequired}
        />
        <TextInput
          label="ra.label.filter"
          source="source.filter"
          resettable
          validate={validateRequired}
        />
        <ArrayInput
          source="actions"
          className={styles.hideElements}
          defaultValue={MARKETING_CAMPAIGN_ACTION_DEFAULT_VALUES}
        >
          <SimpleFormIterator disableAdd disableRemove>
            <SelectInput
              label="ra.label.marketing_campaigns_action_type"
              source="type"
              choices={MARKETING_CAMPAIGN_ACTION_TYPE}
              validate={validateRequired}
            />
            <ReferenceInput label="ra.label.discount" reference="discount" source="discountId">
              <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput label="ra.label.validityInHours" source="validityInHours" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default MarketingCampaignEdit;
