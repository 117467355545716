import React, { useEffect, useState, useMemo } from 'react';
import { Paper, Tabs, Tab as MuiTab } from '@mui/material';
import {
  List,
  useListController,
  useResourceContext,
  TextInput,
  ListContextProvider,
  useGetList,
  useList,
} from 'react-admin';
import OrderListDatagrid from '../OrderListDatagrid';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import url from '../../../config/connection';
import PostPagination from '../PostPagination';
import OrderListHeader from '../OrderListHeader';
import OrderModals from '../OrderModals';
import DetailsMap from '../DetailsMap';
import { ORDER_STATUS_NAMES } from '../../../config/statuses.config';
import { P } from '../../../utils/customTheme';
import DataRange from '../../../DiscountReport/components/Filter/DataRange';

const listResourceMap = {
  'order/today': 'order/today',
  'order/history': 'order/history',
  'order/future': 'order/v1/scheduled',
};

const neededSupplierStatuses = [
  ORDER_STATUS_NAMES.NEW,
  ORDER_STATUS_NAMES.PROCESSING,
  ORDER_STATUS_NAMES.CONFIRMED,
  ORDER_STATUS_NAMES.PREPARING,
  ORDER_STATUS_NAMES.DELIVERING,
  ORDER_STATUS_NAMES.DELIVERED,
  ORDER_STATUS_NAMES.CANCELED,
];

const ListHeader = ({ headerName }) => (
  <div
    style={{
      padding: '10px 0',
      border: '1px solid #d8d3d3',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 600,
      color: P.primary,
      backgroundColor: '#EBF7F7',
      borderRadius: '12px 12px 0 0',
    }}
  >
    {`${headerName} Orders`}
  </div>
);

const OrderList = ({ createSocket, socket }) => {
  const resource = useResourceContext();
  const isTodayOrdersPage = listResourceMap[resource] === 'order/today';
  const listContext = useListController({
    ...(isTodayOrdersPage && { perPage: 500 }),
  });

  const [orderSummary, setOrderSummary] = useState(null);
  const [tab, setTab] = useState(0);

  const { data: todayOrdersList, isLoading } = useGetList(
    'order/today',
    {
      pagination: {
        page: listContext?.page,
        perPage: listContext?.perPage,
      },
    },
    {
      refetchInterval: 10000,
      refetchIntervalInBackground: 10000,
    },
  );
  const getListContext = useList({ isLoading, perPage: 500 });

  const filteredByStatusOrders = useMemo(() => {
    return neededSupplierStatuses.reduce((ordersAccumulator, status) => {
      let filteredOrders;

      if (status === ORDER_STATUS_NAMES.CANCELED) {
        // Include CANCELED, PAYMENT_FAILED, and AWAITING_PAYMENT orders
        filteredOrders = todayOrdersList?.filter(
          (order) =>
            order.supplierStatus === ORDER_STATUS_NAMES.CANCELED ||
            order.supplierStatus === ORDER_STATUS_NAMES.PAYMENT_FAILED ||
            order.supplierStatus === ORDER_STATUS_NAMES.AWAITING_PAYMENT,
        );
      } else if (status === ORDER_STATUS_NAMES.CONFIRMED) {
        // Include CONFIRMED, ACCEPTED orders
        filteredOrders = todayOrdersList?.filter(
          (order) =>
            order.supplierStatus === ORDER_STATUS_NAMES.CONFIRMED ||
            order.supplierStatus === ORDER_STATUS_NAMES.ACCEPTED,
        );
      } else if (status === ORDER_STATUS_NAMES.PROCESSING) {
        // Include PROCESSING, SCHEDULED orders
        filteredOrders = todayOrdersList?.filter(
          (order) =>
            order.supplierStatus === ORDER_STATUS_NAMES.PROCESSING ||
            order.supplierStatus === ORDER_STATUS_NAMES.SCHEDULED,
        );
      } else if (status === ORDER_STATUS_NAMES.PREPARING) {
        // Include PREPARING, PREPARED orders
        filteredOrders = todayOrdersList?.filter(
          (order) =>
            order.supplierStatus === ORDER_STATUS_NAMES.PREPARING ||
            order.supplierStatus === ORDER_STATUS_NAMES.PREPARED,
        );
      } else {
        // Include orders with the specified status
        filteredOrders = todayOrdersList?.filter((order) => order.supplierStatus === status);
      }

      return {
        ...ordersAccumulator,
        [status]: filteredOrders,
      };
    }, {});
  }, [todayOrdersList]);

  const handleTabChange = (_, newValue) => {
    setTab(newValue);
  };

  const fetchSummary = () => {
    fetchWithAuthorization(`${url}/order/summary`)
      .then((results) => results.json())
      .then((data) => setOrderSummary(data));
  };

  useEffect(() => {
    fetchSummary();
  }, []);

  const postFilters = [
    <TextInput
      label="ID"
      source="id"
      alwaysOn
      parse={(value) => value.replace(/[+/&#?]/g, '')}
      sx={{ width: 72 }}
    />,
    <TextInput
      label="Email"
      source="client.email"
      alwaysOn
      parse={(value) => value.replace(/[+/&#?]/g, '')}
    />,
    <TextInput
      label="Company Name"
      source="client.companyName"
      alwaysOn
      parse={(value) => value.replace(/[+/&#?]/g, '')}
    />,
    <TextInput
      label="Client phone"
      source="client.phone"
      alwaysOn
      parse={(value) => value.replace(/[+/&#?]/g, '')}
    />,
    <DataRange alwaysOn resettable />,
  ];

  return (
    <>
      {isTodayOrdersPage && (
        <Paper square>
          <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
            <MuiTab label="List" />
            <MuiTab label="Map" />
          </Tabs>
        </Paper>
      )}
      {tab === 0 && (
        <>
          {isTodayOrdersPage && (
            <div>
              <OrderListHeader orderSummary={orderSummary} isTodayOrdersPage={isTodayOrdersPage} />
              {neededSupplierStatuses.map((orderSupplierStatus) => {
                const filteredData = filteredByStatusOrders[orderSupplierStatus];

                const finalValue = { ...getListContext, data: filteredData };
                const styles = {
                  // padding: '0px 0 15px 0',
                  // border: '1px solid #d8d3d3',
                  margin: '50px 0',
                };

                return (
                  <div style={styles}>
                    <ListContextProvider value={finalValue} key={orderSupplierStatus}>
                      <ListHeader headerName={orderSupplierStatus} />
                      {finalValue?.data?.length === 0 && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            border: '1px solid #d8d3d3',
                            padding: '20px 0',
                          }}
                        >
                          No orders!
                        </div>
                      )}
                      <OrderListDatagrid isTodayOrdersPage={isTodayOrdersPage} />
                    </ListContextProvider>
                  </div>
                );
              })}
            </div>
          )}
          {!isTodayOrdersPage && (
            <List
              pagination={<PostPagination />}
              resource={listResourceMap[resource]}
              actions={isTodayOrdersPage && <OrderListHeader orderSummary={orderSummary} />}
              queryOptions={{
                refetchInterval: 10000, // 10 seconds
                refetchIntervalInBackground: 10000, // 10 seconds
              }}
              filters={postFilters}
            >
              <OrderListDatagrid listProps={listContext} />
            </List>
          )}
          <OrderModals />
        </>
      )}
      {tab === 1 && <DetailsMap />}
    </>
  );
};

export default OrderList;
