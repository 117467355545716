import React, { useState } from 'react';
import {
  Button,
  Create,
  SimpleForm,
  TextInput,
  useRecordContext,
  useTranslate,
  required,
  minValue,
  BooleanInput,
  NumberInput,
  useCreate,
  useRefresh,
  useNotify,
} from 'react-admin';
import { withStyles } from '@mui/styles';
import { Dialog } from '@mui/material';
import { TranslatableTextInput } from '../../../components/Translatable';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import url from '../../../config/connection';

const styles = {
  button: {
    marginTop: '1em',
    height: '40px',
    maxWidth: '200px',
    width: '100%',
    backgroundColor: '#2196f3',
    color: '#fff',
    border: 'none',
    '&:hover': {
      backgroundColor: '#2196f3d6',
      border: 'none',
    },
  },
};

const validateRequired = required();
const validatePositiveRange = [minValue(0)];
const tenantId = Number(localStorage.getItem('tenantId'));

const AddOptionGroupDialog = ({ classes, handleChangeOptionGroup: setOptionGroups }) => {
  const refresh = useRefresh();
  const translate = useTranslate();
  const notify = useNotify();
  const record = useRecordContext();

  const [create] = useCreate();

  const [open, setOpen] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false);

  const handleSave = (data) => {
    create(
      'optionGroup',
      { data: { ...data, supplierId: record?.supplierId, tenantId } },
      {
        onSuccess: (res) => {
          fetchWithAuthorization(`${url}/optionGroup/${res?.id}`)
            .then((response) => response.json())
            .then((finalData) => {
              setOpen(false);
              setOptionGroups((prev) => [...prev, finalData]);
              refresh();
            });
        },
        onError: () => {
          notify(translate('ra.page.error'), { type: 'error' });
        },
      },
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Create sx={{ width: 500, '& .RaCreate-main': { mt: 0 } }}>
          <SimpleForm defaultValues={{ index: 0 }} onSubmit={handleSave}>
            <TranslatableTextInput
              label="ra.label.name"
              source="name"
              validate={validateRequired}
              sx={{ width: '100%' }}
            />
            <TextInput label="ra.label.external" source="externalId" fullWidth />
            {isMultiple && (
              <>
                <NumberInput
                  label="ra.label.maxSelectedOptions"
                  source="maxSelectedOptions"
                  validate={validatePositiveRange}
                  style={{ minWidth: '270px' }}
                />
                <span style={{ fontSize: '13px', color: '#0000008a', display: 'block' }}>
                  {translate('ra.label.unlimitedOptions')}
                </span>
              </>
            )}
            <BooleanInput
              label="ra.label.is_multiple"
              source="isMultiple"
              onChange={(e) => setIsMultiple(e.target.checked)}
            />
            <BooleanInput label="ra.label.is_required" source="required" />
          </SimpleForm>
        </Create>
      </Dialog>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleOpen}
        label={translate('ra.action.add_option_group')}
        title={translate('ra.action.add_option_group')}
      />
    </>
  );
};

export default withStyles(styles)(AddOptionGroupDialog);
