import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  useEditController,
  useRedirect,
  useUpdate,
} from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';

const validateRequired = required();

const LocaleEdit = () => {
  const editController = useEditController();
  const redirect = useRedirect();
  const [update] = useUpdate();

  const onSubmit = (data) => {
    update(
      'ui/locale',
      { id: editController.record.id, data, previousData: editController.record },
      {
        onSuccess: () => {
          redirect('list', 'ui/locale');
        },
      },
    );
  };

  return (
    <Edit actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />} onSubmit={onSubmit}>
        <TextInput label="ra.label.locale" source="locale" resettable validate={validateRequired} />
        <TextInput label="ra.label.long" source="long" resettable validate={validateRequired} />
        <TextInput label="ra.label.short" source="short" resettable validate={validateRequired} />
      </SimpleForm>
    </Edit>
  );
};

export default LocaleEdit;
