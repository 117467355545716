import TenantAdminsCreate from './components/Create';
import TenantAdminsEdit from './components/Edit';
import TenantAdminsList from './components/List';
import TenantAdminsShow from './components/Show';

export default {
  list: TenantAdminsList,
  show: TenantAdminsShow,
  create: TenantAdminsCreate,
  edit: TenantAdminsEdit,
};
