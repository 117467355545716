import React, { useEffect } from 'react';
import './prevent-roboto';
import './config/firebase';
import { Admin } from 'react-admin';
import { Provider } from 'react-redux';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { BrowserRouter } from 'react-router-dom';
import authProvider from './services/authProvider.service';
import MyLayout from './components/Layout/MyLayout';
import renderRoutes from './services/routes.service';
import enTranslations from './translations/enTranslations';
import LoginWithTheme from './components/Layout/Login/Login';
import customStore from './redux';
import dataProvider from './services/dataProvider.service';
import { postWithAuthorization } from './utils/fetchWithAuthorization';
import { baseUrl } from './config/connection';
import { ENABLED_FEATURE_FLAGS } from './config/enabledFeatureFlags';
import { useInterval } from './hooks/useInterval';
import { ALL_USER_ROLES_NAMES } from './config/user.config';
import customTheme from './utils/customTheme';

const currentLocale = localStorage.getItem('currentLocale') || 'en';

const translations = {
  en: { ...enTranslations },
};

const i18nProvider = polyglotI18nProvider((locale) => translations[locale], currentLocale, {
  allowMissing: true,
});

const tokenFromQuery = window.location.href.match(/\?.+/);

if (tokenFromQuery) {
  const params = new URLSearchParams(tokenFromQuery[0]);
  const authToken = params.get('token');
  const role = params.get('role');
  const supplierId = params.get('supplierId');
  const refreshToken = params.get('refreshToken');
  const expirationTime = params.get('expirationTime');
  const tenantId = params.get('tenantId');

  if (Boolean(authToken) && Boolean(role)) {
    localStorage.setItem('token', authToken);
    localStorage.setItem('role', role);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('expirationTime', expirationTime);
    localStorage.setItem('tenantId', tenantId);
  }

  if (role !== ALL_USER_ROLES_NAMES.ADMIN) {
    localStorage.setItem('supplierId', supplierId);

    window.location.href = window.location.origin;
  }
}

const App = () => {
  useEffect(() => {
    try {
      const savedLocales = JSON.parse(localStorage.locales);

      if (!Array.isArray(savedLocales)) {
        throw new Error('Invalid list of locales in local storage');
      }
    } catch {
      localStorage.locales = '[]';
    }

    localStorage.setItem('currentLocale', 'en');
  }, []);

  useEffect(() => {
    const fetchEnabledFeatureFlags = async () => {
      const featureNames = [ENABLED_FEATURE_FLAGS.SERVICE_FEES];

      const request = await postWithAuthorization(`${baseUrl}/feature-flags/enabled`, {
        method: 'POST',
        body: JSON.stringify({
          featureNames,
        }),
      });

      const response = await request.json();

      if (response) {
        localStorage.setItem('enabledFeatureFlags', JSON.stringify(response));
      }
    };
    const token = localStorage.getItem('token');

    if (token !== 'undefined') {
      fetchEnabledFeatureFlags();
    }
  }, []);

  useInterval(() => {
    authProvider.checkAuth();
  }, 1000 * 60); // 1 minute

  return (
    <Provider store={customStore}>
      <BrowserRouter>
        <Admin
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          title="Dely Admin"
          theme={customTheme}
          layout={MyLayout}
          loginPage={LoginWithTheme}
          locale={currentLocale}
        >
          {(permissions) => renderRoutes(permissions)}
        </Admin>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
