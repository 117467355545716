import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CompanyCreate from './components/Create';
import CompanyEdit from './components/Edit';
import CompanyList from './components/List';
import CompanyShow from './components/Show';

export default {
  list: CompanyList,
  create: CompanyCreate,
  edit: CompanyEdit,
  show: CompanyShow,
  icon: BusinessOutlinedIcon,
};
