import React, { useEffect } from 'react';
import {
  SimpleForm,
  Edit,
  SelectInput,
  TextInput,
  ReferenceInput,
  required,
  email,
  useEditController,
  AutocompleteInput,
  useUpdate,
  useRedirect,
} from 'react-admin';
import { useForm, useWatch } from 'react-hook-form';
import PhoneInput from '../../../../components/PhoneInput';
import ResourceTitle from '../../../../components/ResourceTitle';
import { tenantAdminsRoleChoices } from '../../../../config/user.config';
import EditDeleteConfirmationButton from '../../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../../components/CancelButton';

const validateRequired = required();
const validateEmail = [validateRequired, email()];

const TenantAdminsEdit = () => {
  const [update] = useUpdate();
  const redirect = useRedirect();

  const { control, reset } = useForm();
  const editController = useEditController();

  const values = useWatch({
    control,
    defaultValue: editController?.record,
  });

  const onSubmit = async () => {
    update(
      'tenant-users',
      {
        id: editController?.record?.id,
        data: { ...editController?.record, ...values },
        previousData: editController?.record,
      },
      {
        onSuccess: () => {
          redirect('list');
        },
      },
    );
  };

  useEffect(() => {
    if (!editController?.isFetching) {
      reset(editController?.record);
    }
  }, [editController?.isFetching, editController?.record, reset]);

  return (
    <Edit title={<ResourceTitle field="username" />} actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />} onSubmit={onSubmit}>
        <TextInput
          label="ra.label.email"
          source="email"
          sx={{ maxWidth: '300px', width: '100%' }}
          validate={validateEmail}
          control={control}
        />
        <PhoneInput
          source="phone"
          sx={{ maxWidth: '300px', width: '100%' }}
          shouldValidate
          control={control}
        />
        <TextInput
          label="ra.label.password"
          type="password"
          source="password"
          validate={validateRequired}
          sx={{ maxWidth: '300px', width: '100%' }}
          control={control}
        />
        <SelectInput
          label="ra.label.role"
          source="role"
          optionValue="value"
          choices={tenantAdminsRoleChoices}
          sx={{ maxWidth: '300px', width: '100%' }}
          validate={validateRequired}
          control={control}
        />
        <ReferenceInput
          label="ra.label.tenant"
          source="tenantId"
          reference="tenant"
          perPage={10000}
        >
          <AutocompleteInput
            sx={{ maxWidth: '300px', width: '100%' }}
            optionText="name"
            validate={validateRequired}
            control={control}
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default TenantAdminsEdit;
