import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LocaleCreate from './components/Create';
import LocaleEdit from './components/Edit';
import LocaleList from './components/List';
import LocaleShow from './components/Show';

export default {
  list: LocaleList,
  create: LocaleCreate,
  show: LocaleShow,
  edit: LocaleEdit,
  icon: LanguageOutlinedIcon,
};
