import React from 'react';
import {
  Edit,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  useEditController,
  useRecordContext,
  useRedirect,
  useUpdate,
} from 'react-admin';
import { useForm, useWatch } from 'react-hook-form';
import CancelButton from '../../../components/CancelButton';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import { TranslatableTextInput } from '../../../components/Translatable';
import { translateUpdateHelper } from '../../../components/Translatable/helper';

const validateRequired = required();

const CategoryTitle = () => {
  const record = useRecordContext();

  return <span>{`${record?.name}`}</span>;
};

const CategoryEdit = (props) => {
  const editController = useEditController();
  const redirect = useRedirect();
  const [update] = useUpdate();

  const { control } = useForm();

  const translationValues = useWatch({
    control,
    defaultValue: {
      traslations: editController?.record?.translations,
    },
  });

  const onSubmit = (data) => {
    translateUpdateHelper(editController.record.translations, data, translationValues);

    update(
      'category',
      { id: editController.record.id, data, previousData: editController.record },
      {
        onSuccess: () => {
          redirect('list', 'category');
        },
      },
    );
  };

  return (
    <Edit {...props} title={<CategoryTitle />} actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />} onSubmit={onSubmit}>
        <TranslatableTextInput label="ra.label.name" source="name" control={control} />
        <TextInput label="ra.label.slug" source="slug" validate={validateRequired} />
        <NumberInput source="rank" />
      </SimpleForm>
    </Edit>
  );
};

export default CategoryEdit;
