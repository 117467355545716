import React from 'react';
import { Create, required, SimpleForm, TextInput, useCreate, useRedirect } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import { TranslatableTextInput } from '../../../components/Translatable';

const validateRequired = required();
const tenantId = Number(localStorage.getItem('tenantId'));

const TagsCreate = () => {
  const [create] = useCreate();
  const redirect = useRedirect();

  const onSubmit = (values) => {
    create(
      'tag',
      {
        data: {
          ...values,
          tenantId,
        },
      },
      {
        onSuccess: () => {
          redirect('list', 'tag');
        },
      },
    );
  };

  return (
    <Create actions={<CancelButton />}>
      <SimpleForm onSubmit={onSubmit}>
        <TextInput
          label="ra.label.name"
          source="name"
          validate={validateRequired}
          sx={{ maxWidth: '270px', width: '100%' }}
        />
        <TranslatableTextInput label="ra.label.label" source="label" validate={validateRequired} />
        <TextInput
          label="ra.label.slug"
          source="slug"
          validate={validateRequired}
          sx={{ maxWidth: '270px', width: '100%' }}
        />
      </SimpleForm>
    </Create>
  );
};

export default TagsCreate;
