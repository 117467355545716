import React from 'react';
import {
  Create,
  SimpleForm,
  useCreate,
  useRedirect,
} from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import UIHtmlSeoForm from '../common/Form';

const tenantId = Number(localStorage.getItem('tenantId'));

const UIBannerCreate = () => {
  const [create] = useCreate();
  const redirect = useRedirect();

  const onSubmit = (values) => {
    create(
      'ui/html-seo',
      {
        data: {
          ...values,
          tenantId,
        },
      },
      {
        onSuccess: () => {
          redirect('list', 'ui/html-seo');
        },
      },
    );
  };

  return (
    <Create redirect="list" actions={<CancelButton />}>
      <SimpleForm onSubmit={onSubmit}>
        <UIHtmlSeoForm />
      </SimpleForm>
    </Create>
  );
};

export default UIBannerCreate;
