import React from 'react';

import {
  required,
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  useCreate,
  useRedirect,
} from 'react-admin';

import useStyles from '../../styles';

import { TRANSPORT_TYPES, DEFAULT_VALUES } from '../../constants';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();
const tenantId = Number(localStorage.getItem('tenantId'));

const CourierPayoutPolicyCreate = () => {
  const styles = useStyles();

  const [create] = useCreate();
  const redirect = useRedirect();

  const onSubmit = (values) => {
    create(
      'policy',
      {
        data: {
          ...values,
          tenantId,
        },
      },
      {
        onSuccess: () => {
          redirect('list', 'policy');
        },
      },
    );
  };

  return (
    <Create redirect="list" actions={<CancelButton />}>
      <SimpleForm onSubmit={onSubmit}>
        <TextInput
          label="ra.label.name"
          source="name"
          sx={{ marginLeft: '15px' }}
          validate={validateRequired}
        />
        <ArrayInput
          source="courierPayoutSettings"
          className={styles.line}
          defaultValue={DEFAULT_VALUES}
        >
          <SimpleFormIterator disableAdd disableRemove disableReordering>
            <SelectInput
              label="ra.label.transport_type"
              source="type"
              choices={TRANSPORT_TYPES}
              className={styles.spaceBetween}
              validate={validateRequired}
            />
            <NumberInput
              label="ra.label.coefficient_value"
              source="coefficient"
              className={styles.spaceBetween}
              validate={validateRequired}
            />
            <ReferenceInput
              label="ra.label.delivery_settings"
              source="deliverySettingId"
              reference="deliverySettings"
              perPage={150}
              className={styles.spaceBetween}
            >
              <SelectInput optionText="name" optionValue="id" validate={validateRequired} />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default CourierPayoutPolicyCreate;
