import React from 'react';
import { AppBar, LoadingIndicator, LocalesMenuButton } from 'react-admin';
import { withStyles } from '@mui/styles';

const styles = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  toolbar: {
    flex: 1,
  },
};

const CustomAppBar = ({ classes, ...props }) => (
  <AppBar
    {...props}
    classes={classes}
    toolbar={
      <>
        <LocalesMenuButton />
        <LoadingIndicator />
      </>
    }
  >
    <div
      style={{
        width: '100px',
        height: '48px',
        marginRight: 'auto',
      }}
    >
      <img
        src="/dely.svg"
        alt="logo"
        style={{
          width: '96px',
          height: '48px',
        }}
      />
    </div>
  </AppBar>
);

export default withStyles(styles)(CustomAppBar);
