import React from 'react';
import {
  required,
  Create,
  TextInput,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  useCreate,
  useRedirect,
} from 'react-admin';
import {
  MARKETING_CAMPAIGN_ACTION_DEFAULT_VALUES,
  MARKETING_CAMPAIGN_ACTION_TYPE,
  MARKETING_CAMPAIGN_SOURCE_TYPE,
} from '../../utils/constants';

import useStyles from '../../style';
import CancelButton from '../../../../components/CancelButton';

const validateRequired = required();
const tenantId = Number(localStorage.getItem('tenantId'));

const MarketingCampaignCreate = (props) => {
  const styles = useStyles();

  const [create] = useCreate();
  const redirect = useRedirect();

  const onSubmit = (values) => {
    create(
      'marketing-campaigns',
      {
        data: {
          ...values,
          tenantId,
        },
      },
      {
        onSuccess: () => {
          redirect('list', 'marketing-campaigns');
        },
      },
    );
  };

  return (
    <Create {...props} redirect="list" actions={<CancelButton />}>
      <SimpleForm onSubmit={onSubmit}>
        <TextInput label="ra.label.name" source="name" validate={validateRequired} />
        <SelectInput
          label="ra.label.marketing_campaign_source_type"
          source="source.type"
          choices={MARKETING_CAMPAIGN_SOURCE_TYPE}
          validate={validateRequired}
        />
        <TextInput
          label="ra.label.filter"
          source="source.filter"
          resettable
          validate={validateRequired}
        />
        <ArrayInput
          source="actions"
          className={styles.hideElements}
          defaultValue={MARKETING_CAMPAIGN_ACTION_DEFAULT_VALUES}
        >
          <SimpleFormIterator disableAdd disableRemove>
            <SelectInput
              label="ra.label.marketing_campaigns_action_type"
              source="type"
              choices={MARKETING_CAMPAIGN_ACTION_TYPE}
              validate={validateRequired}
            />
            <ReferenceInput label="ra.label.discount" reference="discount" source="discountId">
              <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput label="ra.label.validityInHours" source="validityInHours" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default MarketingCampaignCreate;
