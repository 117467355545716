import React, { useEffect } from 'react';
import {
  SimpleForm,
  Edit,
  SelectInput,
  TextInput,
  ReferenceInput,
  required,
  email,
  useEditController,
  AutocompleteInput,
  useUpdate,
  useRedirect,
} from 'react-admin';
import { useForm, useWatch } from 'react-hook-form';
import PhoneInput from '../../../../components/PhoneInput';
import ResourceTitle from '../../../../components/ResourceTitle';
import SupplierAutocomplete from '../../../../components/SupplierAutocomplete';
import {
  ALL_USER_ROLES_IDS as roleIds,
  ALL_USER_ROLES_NAMES as roleNames,
  adminRoleChoices,
} from '../../../../config/user.config';
import EditDeleteConfirmationButton from '../../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../../components/CancelButton';

const validateRequired = required();
const validateEmail = [validateRequired, email()];

const UserEdit = (props) => {
  const supplierId = localStorage.getItem('supplierId');
  const role = localStorage.getItem('role');

  const [update] = useUpdate();
  const redirect = useRedirect();

  const { control, reset } = useForm();
  const editController = useEditController();

  const values = useWatch({
    control,
    defaultValue: editController?.record,
  });

  const onSubmit = async () => {
    update(
      'user',
      {
        id: editController?.record?.id,
        data: { ...editController?.record, ...values },
        previousData: editController?.record,
      },
      {
        onSuccess: () => {
          redirect('list');
        },
      },
    );
  };

  useEffect(() => {
    if (!editController?.isFetching) {
      reset(editController?.record);
    }
  }, [editController?.isFetching, editController?.record, reset]);

  return (
    <Edit {...props} title={<ResourceTitle field="username" />} actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />} onSubmit={onSubmit}>
        <TextInput
          label="ra.label.email"
          source="email"
          validate={validateEmail}
          sx={{ maxWidth: '300px', width: '100%' }}
          disabled
          control={control}
        />
        <PhoneInput
          source="phone"
          shouldValidate
          sx={{ maxWidth: '300px', width: '100%' }}
          control={control}
        />
        <TextInput
          label="ra.label.first_name"
          source="firstName"
          sx={{ maxWidth: '300px', width: '100%' }}
          control={control}
        />
        <TextInput
          label="ra.label.last_name"
          source="lastName"
          sx={{ maxWidth: '300px', width: '100%' }}
          control={control}
        />
        {role === roleNames.ADMIN && (
          <SelectInput
            label="ra.label.role"
            source="role"
            optionValue="value"
            choices={adminRoleChoices}
            validate={validateRequired}
            sx={{ maxWidth: '300px', width: '100%' }}
          />
        )}
        {values?.role !== roleIds.ADMIN && values?.role !== roleIds.USER && (
          <SupplierAutocomplete sx={{ maxWidth: '300px', width: '100%' }} />
        )}
        {values?.role === roleIds.SUPPLIER_BRANCH && (
          <ReferenceInput
            label="ra.label.branch"
            source="branchId"
            reference="address"
            filter={{ supplierId }}
            default=""
            style={{ width: '256px' }}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
