import React from 'react';
import {
  Edit,
  required,
  SimpleForm,
  TextInput,
  useEditController,
  useRedirect,
  useUpdate,
} from 'react-admin';
import { useForm, useWatch } from 'react-hook-form';
import { TranslatableTextInput } from '../../../components/Translatable';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';
import { translateUpdateHelper } from '../../../components/Translatable/helper';

const validateRequired = required();

const TagsEdit = () => {
  const editController = useEditController();
  const redirect = useRedirect();
  const [update] = useUpdate();

  const { control } = useForm();

  const translationValues = useWatch({
    control,
    defaultValue: {
      traslations: editController?.record?.translations,
    },
  });

  const onSubmit = (data) => {
    translateUpdateHelper(editController?.record?.translations, data, translationValues);

    update(
      'tag',
      { id: editController.record.id, data, previousData: editController.record },
      {
        onSuccess: () => {
          redirect('list', 'tag');
        },
      },
    );
  };

  return (
    <Edit actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />} onSubmit={onSubmit}>
        <TextInput
          label="ra.label.name"
          source="name"
          validate={validateRequired}
          sx={{ maxWidth: '270px', width: '100%' }}
        />
        <TranslatableTextInput
          label="ra.label.label"
          source="label"
          validate={validateRequired}
          control={control}
        />
        <TextInput
          label="ra.label.slug"
          source="slug"
          validate={validateRequired}
          sx={{ maxWidth: '270px', width: '100%' }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default TagsEdit;
