import React from 'react';
import {
  Create,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useCreate,
  useRedirect,
} from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import { FilterTypeListInput } from '../Filters/FilterTypeList';
import { PerkTypeListInput } from '../Perks/PerkTypeList';

const validateRequired = required();

const DiscountCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton alwaysEnable />
  </Toolbar>
);

const tenantId = Number(localStorage.getItem('tenantId'));

const DiscountCreate = (props) => {
  const [create] = useCreate();
  const redirect = useRedirect();

  const onSubmit = (values) => {
    create(
      'discount',
      {
        data: {
          ...values,
          tenantId,
        },
      },
      {
        onSuccess: () => {
          redirect('list', 'discount');
        },
      },
    );
  };

  return (
    <Create {...props} redirect="list" actions={<CancelButton />}>
      <SimpleForm toolbar={<DiscountCreateToolbar />} onSubmit={onSubmit}>
        <TextInput source="name" name="name" validate={validateRequired} />
        <TextInput source="label" name="label" validate={validateRequired} />
        <FilterTypeListInput source="filters" />
        <PerkTypeListInput source="perks" />
      </SimpleForm>
    </Create>
  );
};

export default DiscountCreate;
