import React, { useEffect, useState } from 'react';
import {
  Edit,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useEditController,
  useRedirect,
} from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
// eslint-disable-next-line max-len
import TranslatableLabel from '../../../components/Translatable/TranslatableFields/TranslatableLabel';
import url, { baseUrl } from '../../../config/connection';
import useStyles from '../../styles';
import { editNavMenu } from '../../utils/requests';
import DragHandle from '../DragHandle';
import { ItemContent, ItemImage, ItemSlug, ListContainer, ListItem } from '../styles';
import AddNewItem from '../AddNewItem';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';
import { useOrganizationLocales } from '../../../hooks/useOrganizationLocales';
import EditItem from '../EditItem';

const validateRequired = required();

const NavigationEdit = () => {
  const [listItems, setListItems] = useState([]);
  const localeList = useOrganizationLocales();

  const editControler = useEditController();
  const redirect = useRedirect();

  useEffect(() => {
    setListItems(editControler?.record?.items);
  }, [editControler.record?.items]);

  const addToItems = (values) => {
    const translationLabel = localeList.reduce(
      (acc, val, idx) => ({
        ...acc,
        [val]: values?.S[`label${idx}`]?.value,
      }),
      {},
    );

    const action = {};

    if (values.action) {
      action.type = values.action;
    }
    if (values.tags) {
      action.tags = values.tags;
    }

    setListItems([
      ...listItems,
      {
        slug: values.slug,
        image: values.cover && values.cover.length ? values.cover : [],
        label: translationLabel,
        action,
      },
    ]);
  };

  const deleteItem = (e) => {
    const newItems = [...listItems];
    newItems.splice(e, 1);
    setListItems(newItems);
  };

  const saveForm = (values) => {
    const navElement = {
      name: values.name,
      slug: values.slug,
      items: listItems,
      storeId: values.storeId,
    };
    editNavMenu(navElement, values);
    redirect('/navigation');
  };

  const styles = useStyles();

  return (
    <Edit actions={<CancelButton />}>
      <SimpleForm onSubmit={saveForm} toolbar={<EditDeleteConfirmationButton />}>
        <TextInput label="ra.label.name" source="name" validate={validateRequired} />
        <TextInput label="ra.label.slug" source="slug" validate={validateRequired} />
        <ReferenceInput source="storeId" reference="store">
          <SelectInput
            label="ra.label.supplier_store"
            optionText="name"
            optionValue="id"
            sx={{ maxWidth: '218px', width: '100%' }}
            validate={validateRequired}
          />
        </ReferenceInput>
        <DragDropContext
          onDragEnd={(param) => {
            const srcI = param.source.index;
            const desI = param.destination?.index;
            if (desI) {
              listItems.splice(desI, 0, listItems.splice(srcI, 1)[0]);
              setListItems(listItems);
            }
          }}
        >
          <ListContainer>
            <TranslatableLabel label="ra.label.items" />
            <Droppable droppableId="droppable-1">
              {(provided, _) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {listItems?.map((item, i) => (
                    <Draggable
                      key={`item.slug + ${i * 2}`}
                      draggableId={`${item.slug}${i}`}
                      index={i}
                    >
                      {/* eslint-disable-next-line no-shadow */}
                      {(provided, snapshot) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            boxShadow: snapshot.isDragging ? '0 0 .4rem #666' : 'none',
                          }}
                        >
                          <div className={styles.itemWrapper}>
                            <DragHandle {...provided.dragHandleProps} />
                            <ItemContent>
                              <ItemSlug>{item.slug}</ItemSlug>
                              {item.image ? (
                                <ItemImage
                                  src={`${baseUrl}/s3/uploads/${item.image[0].url}`}
                                  alt="item"
                                  width="80"
                                  height="80"
                                />
                              ) : null}
                            </ItemContent>
                            <div className={styles.actionsWrapper}>
                              <div>
                                <EditItem
                                  index={i}
                                  listItem={item}
                                  saveItem={(index, values) => {
                                    const newItems = [...listItems];
                                    const oldItem = newItems[index];
                                    newItems[index] = {
                                      slug: values.slug,
                                      image: values.image,
                                      label: oldItem.label,
                                      action: {
                                        type: values.action,
                                        tags: values.tags,
                                      },
                                    };
                                    setListItems(newItems);
                                  }}
                                />
                              </div>
                              <div>
                                <DeleteIcon
                                  className={styles.delIcon}
                                  onClick={() => {
                                    deleteItem(i);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <AddNewItem addItem={addToItems} />
          </ListContainer>
        </DragDropContext>
      </SimpleForm>
    </Edit>
  );
};

export default NavigationEdit;
