import React from 'react';
import { Datagrid, DatagridBody } from 'react-admin';
import DatagridRow from './DatagridRow';

const MyDatagridBody = (props) => <DatagridBody {...props} row={<DatagridRow {...props} />} />;
const CustomDatagrid = (props) => (
  <Datagrid
    sx={{
      border: '1px solid #d8d3d3',
    }}
    {...props}
    body={<MyDatagridBody {...props} />}
  />
);

export default CustomDatagrid;
