import React from 'react';
import { required, Create, TextInput, SimpleForm, useCreate, useRedirect } from 'react-admin';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();
const tenantId = Number(localStorage.getItem('tenantId'));

const LocaleCreate = () => {
  const [create] = useCreate();
  const redirect = useRedirect();

  const onSubmit = (values) => {
    create(
      'ui/locale',
      {
        data: {
          ...values,
          tenantId,
        },
      },
      {
        onSuccess: () => {
          redirect('list', 'ui/locale');
        },
      },
    );
  };

  return (
    <Create actions={<CancelButton />}>
      <SimpleForm onSubmit={onSubmit}>
        <TextInput label="ra.label.locale" source="locale" resettable validate={validateRequired} />
        <TextInput label="ra.label.long" source="long" resettable validate={validateRequired} />
        <TextInput label="ra.label.short" source="short" resettable validate={validateRequired} />
      </SimpleForm>
    </Create>
  );
};

export default LocaleCreate;
