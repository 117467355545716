const RESOURCES_NAMES = {
  ORDER: 'order/today',
  ORDER_HISTORY: 'order/history',
  ORDER_SCHEDULED: 'order/v1/scheduled',
  SUPPLIER: 'supplier',
  ADDRESS: 'address',
  STORE: 'store',
  STORE_SUPPLIER: 'store-supplier',
  GOOD: 'goods',
  VARIANT: 'variants',
  CATEGORY: 'category',
  MENU: 'menu',
  OPTION_GROUP: 'optionGroup',
  OPTION: 'option',
  USER: 'user',
  TENANT_USERS: 'tenant-users',
  USER_ADDRESS: 'useraddress',
  COURIER: 'courier',
  TRANSACTION: 'transaction',
  PAYMENT_CONFIG: 'paymentConfig',
  DISCOUNT: 'discount',
  DELIVERY_SETTINGS: 'deliverySettings',
  TAGS: 'tag',
  NAVIGATION: 'navigation',
  DISCOUNT_REPORT: 'reportsDiscount',
  POLICY: 'policy',
  CAMPAIGNS: 'marketing-campaigns',
  RUN: 'marketing-campaign-runs',
  USER_DELETING: 'user/deleted',
  COMPANY: 'company',
  LOCALE: 'ui/locale',
  STATICS: 'ui/static',
  TRANSLATIONS: 'ui/translation',
  FOOTER: 'ui/footer',
  BRANDING: 'ui/theme',
  BANNERS: 'ui/banner',
  SETTINGS: 'ui/settings',
  HTML_SEO: 'ui/html-seo',
  NOTIFICATION: 'notification',
  NOTIFICATION_LOGGING: 'notification-logging',
  DOCUMENT: 'document',
  PROMOCODE: 'promocode',
  SERVICE_FEES: 'service-fees',
  TENANT: 'tenant',
};

export default RESOURCES_NAMES;
