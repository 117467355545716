import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import {
  required,
  Create,
  TextInput,
  SimpleForm,
  useCreate,
  regex,
  useRedirect,
} from 'react-admin';
import Editor from '@monaco-editor/react';
import CancelButton from '../../../components/CancelButton';
import { putWithAuthorization } from '../../../utils/fetchWithAuthorization';
import url from '../../../config/connection';
import { a11yProps } from '../../../utils/a11yProps';
import { TabPanel } from '../../../components/MuiTabPanel';
import { PreviewFrame } from '../styles';
import { PreviewTemplate } from '../consts';
import { useOrganizationLocales } from '../../../hooks/useOrganizationLocales';

const validateRequired = required();
const tenantId = Number(localStorage.getItem('tenantId'));

const pageNameRegex = regex(
  /^(?!-)[a-z]+(?:-[a-z]+)*(?<!-)$/,
  'String should contain only lowercase letters or dashes("-"). Example: abc or abc-def',
);
const pageNameValidator = [validateRequired, pageNameRegex];

const UIStaticPagesCreate = () => {
  const [currentLocale, setCurrentLocale] = useState('en'); // should be one default locale which is always available
  const [pageContentsByLocale, setPageContentsByLocale] = useState({});
  const localeList = useOrganizationLocales();

  const [create] = useCreate();
  const redirect = useRedirect();

  const previewFrameRef = useRef();

  const pageSave = async (data) => {
    Object.entries(pageContentsByLocale).map(([locale, file]) => {
      return putWithAuthorization(`${url}/ui/static/${data.slug}/${locale}`, {
        body: JSON.stringify({
          file,
        }),
      });
    });

    create(
      'ui/static',
      { data: { ...data, tenantId } },
      {
        onSuccess: () => {
          redirect('list', 'ui/static');
        },
      },
    );
  };

  const handleChange = (_event, newValue) => {
    setCurrentLocale(newValue);
  };

  const activeDocument = pageContentsByLocale[currentLocale] ?? '';
  useEffect(() => {
    if (!previewFrameRef.current) {
      return;
    }

    previewFrameRef.current.contentDocument.body.innerHTML = activeDocument;
    setTimeout(() => {
      previewFrameRef.current.style.height =
        `${previewFrameRef.current.contentDocument.body?.clientHeight}px`;
    });
  }, [activeDocument]);

  return (
    <Create actions={<CancelButton />}>
      <SimpleForm onSubmit={pageSave}>
        <TextInput label="ra.label.title" source="title" resettable validate={validateRequired} />
        <TextInput label="ra.label.slug" source="slug" resettable validate={pageNameValidator} />
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentLocale} onChange={handleChange} aria-label="locales tabs">
              {localeList.map((locale, idx) => {
                return (
                  <Tab
                    value={locale}
                    label={locale}
                    key={locale}
                    sx={{ width: '125px' }}
                    {...a11yProps(idx)}
                  />
                );
              })}
            </Tabs>
          </Box>
          <TabPanel value={currentLocale} index={currentLocale}>
            {/* TODO: Make Monaco-Editor compatible with react-hook-form */}
            <Editor
              language="html"
              value={activeDocument}
              height={500}
              onChange={(value) => {
                setPageContentsByLocale((localeDefaults) => ({
                  ...localeDefaults,
                  [currentLocale]: value,
                }));
              }}
            />
          </TabPanel>

          <PreviewFrame
            title={`preview-${currentLocale}`}
            srcDoc={PreviewTemplate}
            ref={previewFrameRef}
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default UIStaticPagesCreate;
