import React from 'react';
import {
  SimpleForm,
  Create,
  SelectInput,
  TextInput,
  ReferenceInput,
  required,
  email,
  AutocompleteInput,
  useCreate,
  useRedirect,
  SaveButton,
  Toolbar,
} from 'react-admin';
import { useForm, useWatch } from 'react-hook-form';
import CancelButton from '../../../../components/CancelButton';
import PhoneInput from '../../../../components/PhoneInput';
import ResourceTitle from '../../../../components/ResourceTitle';
import {
  ALL_USER_ROLES_IDS as roleIds,
  ALL_USER_ROLES_NAMES as roleNames,
  adminRoleChoices,
} from '../../../../config/user.config';

const validateRequired = required();
const validateEmail = [validateRequired, email()];

const UserToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton alwaysEnable />
  </Toolbar>
);

const tenantId = Number(localStorage.getItem('tenantId'));

const UserCreate = () => {
  const { control } = useForm();
  const [create] = useCreate();
  const redirect = useRedirect();

  const supplierIdFromLocalStorage = localStorage.getItem('supplierId');
  const role = localStorage.getItem('role');
  const isCurrentUserSupplierAdmin = role === roleNames.SUPPLIER_ADMIN;
  const isCurrentUserAdmin = role === roleNames.ADMIN;

  const values = useWatch({
    control,
    defaultValue: {
      role: roleIds.SUPPLIER_BRANCH,
    },
  });

  const selectedSupplier = isCurrentUserSupplierAdmin
    ? supplierIdFromLocalStorage
    : values?.supplierId;

  const onSubmit = async (data) => {
    create(
      'user',
      { data: { ...data, ...values, tenantId } },
      {
        onSuccess: () => {
          redirect('list');
        },
      },
    );
  };

  return (
    <Create title={<ResourceTitle field="username" />} actions={<CancelButton />} redirect="list">
      <SimpleForm onSubmit={onSubmit} toolbar={<UserToolbar />}>
        <TextInput
          label="ra.label.email"
          source="email"
          sx={{ maxWidth: '300px', width: '100%' }}
          validate={validateEmail}
          control={control}
        />
        <PhoneInput
          source="phone"
          sx={{ maxWidth: '300px', width: '100%' }}
          shouldValidate
          control={control}
        />
        <TextInput
          label="ra.label.password"
          type="password"
          source="password"
          validate={validateRequired}
          sx={{ maxWidth: '300px', width: '100%' }}
          control={control}
        />
        <SelectInput
          label="ra.label.role"
          source="role"
          optionValue="value"
          choices={adminRoleChoices}
          sx={{ maxWidth: '300px', width: '100%' }}
          validate={validateRequired}
          control={control}
        />
        {values?.role !== roleIds.ADMIN && values?.role !== roleIds.USER && (
          <ReferenceInput
            label="ra.label.supplier"
            source="supplierId"
            reference="supplier"
            perPage={10000}
            defaultValue={isCurrentUserAdmin ? null : supplierIdFromLocalStorage}
            style={{ display: isCurrentUserAdmin ? 'flex' : 'none' }}
          >
            <AutocompleteInput
              sx={{ maxWidth: '300px', width: '100%' }}
              optionText="name"
              validate={validateRequired}
              control={control}
            />
          </ReferenceInput>
        )}
        {selectedSupplier && values?.role === roleIds.SUPPLIER_BRANCH && (
          <ReferenceInput
            label="ra.label.branch"
            source="branchId"
            reference="address"
            perPage={250}
            filter={{ supplierId: selectedSupplier }}
            default=""
            sx={{ maxWidth: '300px', width: '100%' }}
          >
            <AutocompleteInput optionText="name" control={control} />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
