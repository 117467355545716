/* eslint-disable max-len */
import React, { useMemo } from 'react';
import {
  Edit,
  SimpleForm,
  useEditController,
  useRedirect,
  useUpdate,
} from 'react-admin';
import { useForm, useWatch } from 'react-hook-form';
import CancelButton from '../../../components/CancelButton';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import UIThemeForm from '../common/Form';

const UIThemeEdit = () => {
  const editController = useEditController();
  const redirect = useRedirect();
  const [update] = useUpdate();
  const { control } = useForm();

  const form = useWatch({
    control,
    defaultValue: editController?.record,
  });

  const previewChanges = useMemo(() => {
    const siteData = {
      ...form,
      logo: form?.logo[0]?.url,
      logoMobile: form?.logoMobile[0]?.url,
    };

    return {
      siteData,
    };
  }, [form]);

  const onSubmit = (data) => {
    update(
      'ui/theme',
      { id: editController.record.id, data: { ...form, ...data }, previousData: editController.record },
      {
        onSuccess: () => {
          redirect('list', 'ui/theme');
        },
      },
    );
  };

  return (
    <Edit actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />} onSubmit={onSubmit}>
        <UIThemeForm previewChanges={previewChanges} />
      </SimpleForm>
    </Edit>
  );
};

export default UIThemeEdit;
