import React from 'react';
import { List, Datagrid, TextField, BooleanField, FunctionField } from 'react-admin';
import { PostFilter } from '../PostFilter';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import SupplierListActions from './ListActions';

const SupplierList = () => {
  return (
    <List filters={<PostFilter />} actions={<SupplierListActions />}>
      <Datagrid
        rowClick="show"
        bulkActionButtons={<ListDeleteConfirmationButton />}
      >
        <TextField label="ra.label.name" source="name" />
        <TextField label="ra.label.highlight" source="highlight" />
        <TextField label="ra.label.id" source="id" />
        <TextField label="ra.label.status" source="status" />
        <FunctionField
          label="ra.label.commission"
          render={(record) => `${record.platformCommission}%`}
        />
        <BooleanField label="ra.label.allow_under_age" source="underAgeAllowed" />
      </Datagrid>
    </List>
  );
};

export default SupplierList;
