/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  required,
  Button,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';
import randomString from 'crypto-random-string';
import IconContentAdd from '@mui/icons-material/Add';
import IconCancel from '@mui/icons-material/Cancel';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button as ButtonMui,
} from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import TranslatableTextInputNav from '../../../components/Translatable/TranslatableInputs/TranslatableTextInputNav';
import TranslatableLabel from '../../../components/Translatable/TranslatableFields/TranslatableLabel';
import { AddImage } from '../styles';
import { baseUrl } from '../../../config/connection';
import S3FileInput from '../../../components/S3FileUploader/Input';

const validateRequired = required();

const AddNewItem = (props) => {
  const { addItem } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [showSelectTags, setShowSelectTags] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const { control, reset } = useForm();

  const values = useWatch({
    control,
  });

  useEffect(() => {
    if (values.action === 'FILTER') {
      setShowSelectTags(true);
    } else {
      setShowSelectTags(false);
    }
  }, [values.action]);

  useEffect(() => {
    setIsFormValid(true);
    if (!values?.slug) {
      setIsFormValid(false);
    }
    if (!values.S) {
      setIsFormValid(false);
    } else {
      Object.values(values?.S).forEach((item) => {
        if (!item?.value) {
          setIsFormValid(false);
        }
      });
    }
  }, [values]);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
    reset();
  };

  const handleSubmit = () => {
    addItem(values);
    reset();
    setShowDialog(false);
  };

  return (
    <>
      <Button onClick={handleClick} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
        closeAfterTransition
        disableEnforceFocus
      >
        <DialogTitle>Create item</DialogTitle>
        <DialogContent>
          <SimpleForm resource="posts" toolbar={null}>
            <TextInput
              source="slug"
              validate={validateRequired}
              control={control}
              sx={{ maxWidth: '270px', width: '100%' }}
            />
            <TranslatableTextInputNav
              label="ra.label.label"
              source="label"
              validate={validateRequired}
              control={control}
              {...props}
            />
            <TranslatableLabel label="ra.label.photo" {...props} />
            <AddImage>
              <S3FileInput
                apiRoot={baseUrl}
                control={control}
                source="cover"
                uploadOptions={{
                  signingUrl: `${baseUrl}/s3/sign`,
                  s3path: `dely/${randomString({ length: 10, type: 'alphanumeric' })}`,
                  multiple: false,
                  accept: 'image/*',
                }}
                fileCoverImg="http://www.sclance.com/pngs/file-png/file_png_487794.jpg"
              />
            </AddImage>
            <SelectInput
              source="action"
              choices={[
                { id: 'FILTER', name: 'FILTER' },
              ]}
              control={control}
              validate={validateRequired}
              sx={{ maxWidth: '270px', width: '100%' }}
            />
            {showSelectTags && (
              <ReferenceArrayInput source="tags" reference="tag">
                <SelectArrayInput
                  optionText="name"
                  optionValue="slug"
                  label="resources.tag.name"
                  control={control}
                  sx={{ maxWidth: '270px', width: '100%' }}
                />
              </ReferenceArrayInput>
            )}
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <ButtonMui onClick={handleSubmit} disabled={!isFormValid}>
            Save
          </ButtonMui>
          <Button label="ra.action.cancel" onClick={handleCloseClick}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddNewItem;
