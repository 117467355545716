import React from 'react';
import { Create, NumberInput, required, SimpleForm, useCreate, useRedirect } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import { TranslatableTextInput } from '../../../components/Translatable';

const validateRequired = required();

const tenantId = Number(localStorage.getItem('tenantId'));

const CategoryCreate = () => {
  const [create] = useCreate();
  const redirect = useRedirect();

  const onSubmit = (values) => {
    create(
      'category',
      {
        data: {
          ...values,
          tenantId,
        },
      },
      {
        onSuccess: () => {
          redirect('list', 'category');
        },
      },
    );
  };

  return (
    <Create actions={<CancelButton />}>
      <SimpleForm redirect="show" onSubmit={onSubmit}>
        <TranslatableTextInput label="ra.label.name" source="name" validate={validateRequired} />
        <NumberInput source="rank" />
      </SimpleForm>
    </Create>
  );
};

export default CategoryCreate;
