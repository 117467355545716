import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  DateTimeInput,
  useCreate,
  useRedirect,
} from 'react-admin';
import CancelButton from '../../../../components/CancelButton';

const validateRequired = required();
const tenantId = Number(localStorage.getItem('tenantId'));

const RunCampaignCreate = (props) => {
  const [create] = useCreate();
  const redirect = useRedirect();

  const onSubmit = (values) => {
    create(
      'marketing-campaign-runs',
      {
        data: {
          ...values,
          tenantId,
        },
      },
      {
        onSuccess: () => {
          redirect('list', 'marketing-campaign-runs');
        },
      },
    );
  };

  return (
    <Create {...props} redirect="list" actions={<CancelButton />}>
      <SimpleForm onSubmit={onSubmit}>
        <ReferenceInput reference="marketing-campaigns" source="campaignId">
          <SelectInput
            optionText="name"
            validate={validateRequired}
            label="ra.label.marketing_campaigns"
            sx={{ maxWidth: '218px', width: '100%' }}
          />
        </ReferenceInput>
        <DateTimeInput
          source="launchDate"
          label="ra.label.launchDate"
          validate={validateRequired}
          sx={{ maxWidth: '218px', width: '100%' }}
        />
        <DateTimeInput
          source="effectiveDate"
          label="ra.label.effectiveDate"
          validate={validateRequired}
          sx={{ maxWidth: '218px', width: '100%' }}
        />
      </SimpleForm>
    </Create>
  );
};

export default RunCampaignCreate;
