import React from 'react';
import { Edit, SimpleForm, useEditController, useRedirect, useUpdate } from 'react-admin';
import DeliverySettingsInput from '../DeliverySettingsFieldInput';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';

const DeliverySettingsEdit = () => {
  const editController = useEditController();
  const redirect = useRedirect();
  const [update] = useUpdate();

  const onSubmit = (data) => {
    update(
      'deliverySettings',
      { id: editController.record.id, data, previousData: editController.record },
      {
        onSuccess: () => {
          redirect('list', 'deliverySettings');
        },
      },
    );
  };

  return (
    <Edit actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />} onSubmit={onSubmit}>
        <DeliverySettingsInput />
      </SimpleForm>
    </Edit>
  );
};

export default DeliverySettingsEdit;
