import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  useCreate,
  useRedirect,
} from 'react-admin';

const validateRequired = required();
const tenantId = Number(localStorage.getItem('tenantId'));

const PromoCodeCreate = () => {
  const [create] = useCreate();
  const redirect = useRedirect();

  const onSubmit = (values) => {
    create(
      'promocode',
      {
        data: {
          ...values,
          tenantId,
        },
      },
      {
        onSuccess: () => {
          redirect('list', 'promocode');
        },
      },
    );
  };

  return (
    <Create>
      <SimpleForm onSubmit={onSubmit}>
        <TextInput source="title" name="title" validate={validateRequired} />
        <ReferenceInput reference="discount" source="discount">
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            label="ra.label.discount"
            sx={{ width: '206px' }}
          />
        </ReferenceInput>
        <NumberInput source="maxUsage" name="maxUsage" />
        <NumberInput source="maxUsagePerUser" name="maxUsagePerUser" />
        <BooleanInput defaultValue source="isActive" name="isActive" />
      </SimpleForm>
    </Create>
  );
};

export default PromoCodeCreate;
