export const orderObjectByKeys = (object) => {
  return Object.keys(object).sort().reduce((obj, key) => {
    obj[key] = object[key];

    return obj;
  }, {});
};

export const translateUpdateHelper = (oldTranslationsRecord, dataToUpdate, currentTranslations) => {
  const oldTranslations = oldTranslationsRecord;
  // Merge the old translations with the new values
  if (oldTranslations && currentTranslations.translations) {
    dataToUpdate.translations = currentTranslations.translations.map((newTranslation, index) => {
      const oldTranslation = oldTranslations[index];

      return {
        ...oldTranslation,
        ...newTranslation,
      };
    });
  }
};
