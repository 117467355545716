/* eslint-disable prefer-promise-reject-errors */
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import url, { mainDomainUrl, urlAuth } from '../config/connection';
import { ALL_USER_ROLES_NAMES } from '../config/user.config';
import { isUser } from '../utils/roles';
import history from '../history';
import { firebaseConfig } from '../config/firebase';

const setSelfManagement = async (supplierId) => {
  if (supplierId) {
    const res = await fetch(`${url}/supplier/${supplierId}`);
    const { allowSelfManagement } = await res.json();
    localStorage.setItem('allowSelfManagement', allowSelfManagement);
  }
};

const auth = getAuth();

const updateToken = async () => {
  const newTokenRequest = await fetch(
    `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`,
    {
      method: 'post',
      body: JSON.stringify({
        grant_type: 'refresh_token',
        refresh_token: localStorage.getItem('refreshToken'),
      }),
    },
  );
  const result = await newTokenRequest.json();

  const currentTime = new Date().getTime();
  const expirationTime = currentTime + 3600000;

  localStorage.setItem('token', result.id_token);
  localStorage.setItem('refreshToken', result.refresh_token);
  localStorage.setItem('expirationTime', expirationTime);
  const firebaseLoaded = () =>
    new Promise((resolve) => {
      auth.onAuthStateChanged(resolve);
    });
  await firebaseLoaded();
};

const authProvider = {
  login: async (params) => {
    const { email, password } = params;

    if (email && password) {
      const currentTime = new Date().getTime();
      const expirationTime = currentTime + 3600000;
      try {
        await setPersistence(auth, browserLocalPersistence);

        await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        const errorMessage = error.message;
        // eslint-disable-next-line no-console
        console.error(errorMessage);

        return Promise.reject(errorMessage);
      }

      const idToken = await getAuth().currentUser?.getIdToken();
      const refreshToken = await getAuth().currentUser?.refreshToken;

      const response = await fetch(`${urlAuth}/loginByEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.status < 200 || response.status >= 400) {
        return Promise.reject(new Error(response.statusText));
      }

      const user = await response.json();
      const userRole = user.roleName;

      if (isUser(user.role)) {
        setTimeout(() => history.push('/'), 1000);

        return Promise.reject(new Error('Forbidden!'));
      }

      await setSelfManagement(user.supplierId);

      localStorage.setItem(
        'currentUser',
        JSON.stringify({ ...user, token: idToken, refreshToken, expirationTime }),
      );
      localStorage.setItem('role', userRole);
      localStorage.setItem('token', idToken);
      localStorage.setItem('supplierId', user.supplierId);

      return user;
    }

    return Promise.resolve();
  },
  logout: () => {
    localStorage.clear();

    getAuth().signOut();

    window.location.href = `${mainDomainUrl}/login`;

    return Promise.resolve();
  },
  checkError: async (error) => {
    const { status } = error;

    const token = localStorage.getItem('token');

    if (token === 'undefined') {
      return Promise.reject({ redirectTo: '/login' });
    }

    if (status === 401 || status === 403) {
      await getAuth().signOut();
      window.location.reload();

      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: async () => {
    const currentTime = new Date().getTime();
    const expirationTime = Number(localStorage.getItem('expirationTime'));
    const twoMinutes = 2 * 60 * 1000;

    const remainingTime = expirationTime - currentTime;

    if (remainingTime < twoMinutes) {
      await updateToken();

      return Promise.resolve();
    }
  },
  getPermissions: async () => {
    const role = localStorage.getItem('role');
    const isNotUser =
      [
        ALL_USER_ROLES_NAMES.ADMIN,
        ALL_USER_ROLES_NAMES.SUPPLIER_ADMIN,
        ALL_USER_ROLES_NAMES.SUPPLIER_BRANCH,
      ].indexOf(role) !== -1;

    return isNotUser ? Promise.resolve(role) : Promise.reject();
  },
  getIdentity: async () => {
    try {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));

      return Promise.resolve(currentUser);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default authProvider;
