import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const TenantList = () => {
  return (
    <List>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.name" source="name" />
        <TextField label="ra.label.slug" source="slug" />
      </Datagrid>
    </List>
  );
};

export default TenantList;
