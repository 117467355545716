import React from 'react';
import { Create, SimpleForm, useCreate, useRedirect } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import DocumentHtmlForm from '../common/Form';

const tenantId = Number(localStorage.getItem('tenantId'));

const DocumentCreate = () => {
  const [create] = useCreate();
  const redirect = useRedirect();

  const onSubmit = (values) => {
    create(
      'document',
      {
        data: {
          ...values,
          tenantId,
        },
      },
      {
        onSuccess: () => {
          redirect('list', 'document');
        },
      },
    );
  };

  return (
    <Create redirect="list" actions={<CancelButton />}>
      <SimpleForm redirect="show" onSubmit={onSubmit}>
        <DocumentHtmlForm />
      </SimpleForm>
    </Create>
  );
};

export default DocumentCreate;
