import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box, Button, FormLabel, Tab, Tabs, TextField } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Form, useNotify } from 'react-admin';
import { set, merge, cloneDeep } from 'lodash';
import { fetchWithAuthorization, putWithAuthorization } from '../utils/fetchWithAuthorization';
import url from '../config/connection';
import { a11yProps } from '../utils/a11yProps'; // TODO: move a11yProps to common components
import { TabPanel } from '../components/MuiTabPanel';
import { downloadContent } from '../utils/downloadContent';
import { useOrganizationLocales } from '../hooks/useOrganizationLocales';

const completeTemplateValues = (generalTemplate = {}, localizedTemplate = {}) => {
  const completedTemplate = { ...generalTemplate }; // Create a copy of the generalTemplate

  Object.keys(generalTemplate).forEach((key) => {
    // Loop through each key in the generalTemplate object
    if (key in localizedTemplate) {
      // If the key exists in the localizedTemplate as well
      if (typeof generalTemplate[key] === 'object' && typeof localizedTemplate[key] === 'object') {
        // If both values are objects, recursively call completeTemplateValues to merge them
        completedTemplate[key] = completeTemplateValues(
          generalTemplate[key],
          localizedTemplate[key],
        );
      } else if (localizedTemplate[key] !== '') {
        // If the localizedTemplate has a non-empty value for the key, use it in completedTemplate
        completedTemplate[key] = localizedTemplate[key];
      }
    } else if (typeof generalTemplate[key] === 'object') {
      // If the key is not present in localizedTemplate but the value is an object in generalTemplate
      // Recursively call completeTemplateValues with an empty object as the localizedTemplate
      completedTemplate[key] = completeTemplateValues(generalTemplate[key], {});
    }
  });

  return completedTemplate; // Return the modified template
};

const UITranslations = () => {
  const [localeValue, setLocaleValue] = useState('en');
  const [baseTemplate, setBaseTemplate] = useState();
  const [templatesToUpload, setTemplatesToUpload] = useState({});
  const locales = useOrganizationLocales();

  const methods = useForm();

  // Subscribed to inputs changes
  const watchTranslationsInputs = useWatch({
    defaultValue: templatesToUpload,
    control: methods.control,
  });

  useEffect(() => {
    if (locales.length) {
      setLocaleValue(locales[0]);
    }
  }, [locales]);

  useEffect(() => {
    Promise.all([
      fetchWithAuthorization(`${url}/ui/footer`),
      fetchWithAuthorization(`${url}/ui/translation/template`),
    ]).then(([footerResponse, translationTemplateResponse]) =>
      Promise.all([
        footerResponse.json(),
        translationTemplateResponse.json(),
      ])).then(([footer, translationTemplate]) => {
      const extendedTranslationTemplate = translationTemplate;
      JSON.stringify(footer, (key, value) => {
        if (key && key === 'title' && typeof value === 'string') {
          set(extendedTranslationTemplate, value, '');
        }

        return value;
      });

      setBaseTemplate(extendedTranslationTemplate);
    });
  }, []);

  useEffect(() => {
    const getLocalizedTemplates = async () => {
      const translations = {};
      await Promise.all(
        locales.map(async (locale) => {
          const response = await fetchWithAuthorization(`${url}/ui/translation/${locale}`);
          const localeData = response.ok ? await response.json() : {};

          translations[locale] = merge(cloneDeep(baseTemplate), localeData);
        }),
      );

      setTemplatesToUpload(translations);
    };

    if (locales.length && baseTemplate) {
      getLocalizedTemplates();
    }
  }, [locales, baseTemplate]);

  useEffect(() => {
    // https://react-hook-form.com/docs/useform/reset
    methods.reset(templatesToUpload);
  }, [methods, methods.reset, templatesToUpload]);

  const uploadTranslations = () => {
    Object.keys(watchTranslationsInputs).map((locale) => {
      return putWithAuthorization(`${url}/ui/translation/${locale}`, {
        body: JSON.stringify({
          file: JSON.stringify(watchTranslationsInputs[locale]),
        }),
      });
    });
  };

  const handleChange = (_event, newValue) => {
    setLocaleValue(newValue);
  };
  const notify = useNotify();
  // eslint-disable-next-line function-paren-newline
  const onTranslationFileUploaded = useCallback(
    /**
     * @param {HTMLInputElement} htmlInputElement
     * @param {string} locale
     */
    (htmlInputElement, locale) => {
      htmlInputElement.files[0].text().then((fileContent) => {
        try {
          const jsonContent = JSON.parse(fileContent);
          setTemplatesToUpload((currentValue) => ({
            ...currentValue,
            [locale]: jsonContent,
          }));
        } catch {
          notify('Could not parse the provided file');
        }

        htmlInputElement.value = '';
      });
    }, [notify]);

  const downloadTranslationFile = useCallback((locale) => {
    downloadContent(JSON.stringify(templatesToUpload[locale], null, 2), `${locale}.json`);
  }, [templatesToUpload]);

  const renderFormFields = (template = {}, levelDepthCount = 0, parentKey = '') => {
    return Object.keys(template).sort().map((field) => {
      if (!field) {
        return null;
      }

      const fieldValue = template[field];
      const fieldKey = parentKey ? `${parentKey}.${field}` : field;
      const fieldUpperCaseFirstLetter = field[0].toUpperCase() + field.slice(1);

      if (typeof fieldValue === 'object') {
        return (
          <Fragment key={field}>
            <FormLabel component="legend" sx={{ marginLeft: `${levelDepthCount * 20}px` }}>
              {fieldUpperCaseFirstLetter}
            </FormLabel>
            {renderFormFields(fieldValue, levelDepthCount + 1, fieldKey)}
          </Fragment>
        );
      }

      return (
        <TextField
          {...methods.register(`${localeValue}.${fieldKey}`)}
          label={field}
          control={methods.control}
          defaultValue={typeof fieldValue !== 'object' && fieldValue}
          key={`${localeValue}-${fieldKey}`}
          sx={{ display: 'block', marginLeft: `${levelDepthCount * 25}px`, marginBottom: '15px' }}
        />
      );
    });
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={uploadTranslations}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={localeValue} onChange={handleChange} aria-label="translations tabs">
              {locales.map((locale, idx) => {
                return (
                  <Tab
                    value={locale}
                    label={locale}
                    key={locale}
                    sx={{ width: '125px' }}
                    {...a11yProps(idx)}
                  />
                );
              })}
            </Tabs>
          </Box>
          <TabPanel value={localeValue} index={localeValue}>
            <div>
              <Button variant="contained" sx={{ 'margin-bottom': '30px' }} onClick={() => document.forms[0].elements['translation-upload'].click()}>
                {`Upload ${localeValue.toUpperCase()} translation`}
              </Button>
              <input
                type="file"
                name="translation-upload"
                style={{ visibility: 'hidden', width: '16px' }}
                onChange={(e) => onTranslationFileUploaded(e.target, localeValue)}
              />

              <Button variant="contained" sx={{ 'margin-bottom': '30px' }} onClick={(e) => downloadTranslationFile(localeValue)}>
                {`Download ${localeValue.toUpperCase()} translation`}
              </Button>
            </div>

            {renderFormFields(templatesToUpload[localeValue])}
          </TabPanel>
        </Box>
        <Button variant="contained" sx={{ margin: '30px' }} type="submit">
          Upload Translations
        </Button>
      </Form>
    </FormProvider>
  );
};

export default UITranslations;
