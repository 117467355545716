import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import UIBannerCreate from './components/Create';
import UIBannerEdit from './components/Edit';
import UIBannerList from './components/List';
import UIBannerShow from './components/Show';

export default {
  list: UIBannerList,
  create: UIBannerCreate,
  show: UIBannerShow,
  edit: UIBannerEdit,
  icon: ViewCarouselOutlinedIcon,
};
