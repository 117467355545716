import React, { useState } from 'react';
import {
  SimpleForm,
  required,
  NumberInput,
  BooleanInput,
  FormDataConsumer,
  TextInput,
  number,
  Create,
  ReferenceInput,
  AutocompleteInput,
  useCreate,
  useRedirect,
} from 'react-admin';
import { TranslatableTextInput } from '../../../components/Translatable';
import { isSuperAdmin } from '../../../utils/roles';
import SupplierAutocomplete from '../../../components/SupplierAutocomplete';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();
const validateStock = [validateRequired, number()];

const OptionFormDataConsumer = ({ formData, ...rest }) => {
  const [isFreeOption, setIsFreeOption] = useState(formData.isFreeOption);

  const handlePriceChange = (e) => {
    setIsFreeOption(e.target.checked);
  };

  return (
    <>
      <BooleanInput label="ra.label.available" source="isActive" />
      <BooleanInput
        label="ra.label.is_free_option"
        source="isFreeOption"
        onChange={(e) => handlePriceChange(e)}
        {...rest}
      />
      <TranslatableTextInput label="ra.label.name" source="name" />
      <div
        style={{
          display: 'flex',
          gap: '1rem',
        }}
      >
        <NumberInput
          parse={(v) => v * 100}
          format={(v) => v / 100}
          label="ra.label.price"
          source="price"
          validate={validateStock}
          {...rest}
        />
        <NumberInput
          label="ra.label.maxQuantity"
          source="maxOptions"
          min={0}
          validate={validateStock}
          {...rest}
        />
        {isFreeOption && (
          <>
            <NumberInput
              label="ra.label.maxFreeQuantity"
              source="maxFreeOptions"
              min={0}
              validate={validateStock}
              {...rest}
            />
            <p
              style={{
                color: 'red',
                fontSize: '12px',
              }}
            >
              *Price applies after max free quantity is reached
            </p>
          </>
        )}
      </div>

      {isSuperAdmin() && <SupplierAutocomplete />}
      <ReferenceInput label="ra.label.goods" reference="optionGroup" source="optionGroupId">
        <AutocompleteInput
          optionText="name"
          optionValue="id"
          sx={{ maxWidth: '218px', width: '100%' }}
        />
      </ReferenceInput>
      <TextInput label="ra.label.external" source="externalId" />
    </>
  );
};

const tenantId = Number(localStorage.getItem('tenantId'));

const OptionCreate = () => {
  const [create] = useCreate();
  const redirect = useRedirect();

  const formRecord = { isActive: true, maxFreeOptions: 1, maxOptions: 1, price: 0 };

  const onSubmit = (values) => {
    create(
      'option',
      {
        data: {
          ...formRecord,
          ...values,
          tenantId,
        },
      },
      {
        onSuccess: () => {
          redirect('list', `optionGroup/${values.optionGroupId}/show/1`);
        },
      },
    );
  };

  return (
    <Create
      actions={<CancelButton />}
    >
      <SimpleForm onSubmit={onSubmit}>
        <FormDataConsumer>
          {(formDataProps) => <OptionFormDataConsumer {...formDataProps} />}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default OptionCreate;
