export const baseUrl = window._env_.REACT_APP_API_URL;
export const awsBucketName = window._env_.REACT_APP_AWS_BUCKET_NAME;
export const staticUrl = `https://${awsBucketName}`;
const url = `${baseUrl}/admin`;

export const urlAuth = `${baseUrl}/auth`;

export const mainDomainUrl = window._env_.REACT_APP_MAIN_URL;
export const adminRoleUrl = window._env_.REACT_APP_ADMIN_URL;
export const httpProtocol = window._env_.REACT_APP_PROTOCOL;

export default url;
