import React, { useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  Box,
} from '@mui/material';
import { Form, SaveButton, useTranslate } from 'react-admin';
import randomString from 'crypto-random-string';
import S3FileInput from '../components/S3FileUploader/Input';
import { useOrganizationLocales } from '../hooks/useOrganizationLocales';
import { baseUrl } from '../config/connection';
import styles from './styles';

const SectionTitleEditDialog = ({ translatable, sectionId, onClose, cover = '' }) => {
  const [translations, setTranslations] = useState(translatable || []);
  const recordCover = useMemo(() => ({ cover: cover ? [{ url: cover }] : [] }), [cover]);
  const locales = useOrganizationLocales();
  const translate = useTranslate();

  useEffect(() => {
    if (translations.length < 3) {
      const newTranslations = locales.map((locale) => ({
        locale,
        className: 'Section',
        objectId: sectionId,
        property: 'label',
        value: '',
      }));

      setTranslations(newTranslations);
    }
  }, [locales, sectionId, translations.length]);

  const handleChange = (locale, value) => {
    const idx = translations.findIndex((translation) => translation.locale === locale);
    const translation = translations[idx];
    translation.value = value;

    const newTranslations = [...translations];
    newTranslations[idx] = translation;

    setTranslations([...newTranslations]);
  };

  const handleSave = (record) => {
    onClose(translations, record.cover?.[0]?.url);
  };

  return (
    <Dialog open={!!sectionId}>
      <Form record={recordCover} onSubmit={handleSave}>
        <DialogTitle>{translate('ra.action.menu_edit_section_title')}</DialogTitle>
        <DialogContent style={styles.flexColumn}>
          <FormControl>
            {translations.map((translation) => (
              <TextField
                variant="standard"
                sx={{ marginTop: '20px' }}
                key={translation.locale}
                label={translation.locale}
                placeholder={translation.locale}
                value={translation.value}
                onChange={(e) => {
                  handleChange(translation.locale, e.target.value);
                }}
              />
            ))}
          </FormControl>

          <Box sx={{ marginTop: '20px' }}>
            <p>{translate('ra.label.cover_image')}</p>
            <S3FileInput
              apiRoot={baseUrl}
              source="cover"
              uploadOptions={{
                id: 'cover',
                signingUrl: `${baseUrl}/s3/sign`,
                s3path: `dely/${randomString({ length: 10, type: 'alphanumeric' })}`,
                multiple: false,
                accept: 'image/*',
              }}
            />
          </Box>
          <FormControl style={styles.modalSaveBtn}>
            <SaveButton alwaysEnable />
          </FormControl>
        </DialogContent>
      </Form>
    </Dialog>
  );
};

export default SectionTitleEditDialog;
