import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import DefaultIcon from '@mui/icons-material/ViewList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItemLink, useTranslate, useSidebarState } from 'react-admin';

const MenuResource = ({
  styles,
  onMenuClick,
  resourcePart,
  navigationTranslateName,
  children,
  ops = { icon: DefaultIcon },
}) => {
  const translate = useTranslate();
  const [open] = useSidebarState();

  return (
    <Accordion
      elevation={0}
      className={styles.subgroup}
    >
      <AccordionSummary expandIcon={open ? <ExpandMoreIcon /> : null} className={styles.summary}>
        <ops.icon sx={{ marginRight: 1 }} />
        <Typography
          sx={{
            fontSize: 13,
            margin: 'auto 0',
            fontWeight: 'bold',
            minHeight: 'auto',
            display: open ? 'block' : 'none',
          }}
        >
          {translate(`ra.navigation.${navigationTranslateName}`)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.expandDetails}>
        {resourcePart.map((res) => {
          const { name, options } = res;

          return (
            <MenuItemLink
              key={name}
              to={`/${name}`}
              primaryText={translate(options?.label || `resources.${name}.name`)}
              leftIcon={res?.icon ? <res.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              className={styles.menuItem}
            />
          );
        })}
        {/* Used for custom resources */}
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default MenuResource;
