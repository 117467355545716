/* eslint-disable max-len */
import React from 'react';
import '../../../config/firebase';

import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetIdentity } from 'react-admin';
import { ALL_USER_ROLES_NAMES } from '../../../config/user.config';
import { adminRoleUrl, httpProtocol } from '../../../config/connection';

const TenatntAuthRedirectPage = () => {
  const { data, isLoading } = useGetIdentity();

  const refreshTokenAndExpirationTime = `refreshToken=${data?.refreshToken}&expirationTime=${data?.expirationTime}`;

  const isNotHyperAdmin = [
    ALL_USER_ROLES_NAMES.SUPPLIER_ADMIN,
    ALL_USER_ROLES_NAMES.SUPPLIER_BRANCH,
  ].includes(data?.roleName);

  if (data?.roleName === ALL_USER_ROLES_NAMES.ADMIN && !data.tenant && !isLoading) {
    const query = `token=${data?.token}&role=${data?.roleName}&${refreshTokenAndExpirationTime}`;

    localStorage.clear();

    const redirectUrl = `${adminRoleUrl}?${query}`;

    window.location.href = redirectUrl;

    return;
  }

  if (isNotHyperAdmin && data?.tenant && !isLoading) {
    const query = `token=${data?.token}&role=${data?.roleName}&supplierId=${data?.supplierId}&tenantId=${data?.tenant?.id}&${refreshTokenAndExpirationTime}`;

    localStorage.clear();

    const redirectUrl = `${httpProtocol}://${data?.tenant?.slug}.${window.location.host}?${query}`;

    window.location.href = redirectUrl;

    return;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress size="100px" />
    </Box>
  );
};

export default TenatntAuthRedirectPage;
