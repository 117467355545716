import React from 'react';
import { List, Datagrid, TextField, ChipField, FunctionField } from 'react-admin';

import ListDeleteConfirmationButton from '../../../../components/ListDeleteConfirmationButton';
import { ListTopToolBar } from '../../../../components/ListTopToolBar';
import { formatPhone } from '../../../../utils/formatPhone';

const TenantAdminsList = () => {
  return (
    <List actions={<ListTopToolBar />}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField source="id" />
        <TextField source="email" />
        <FunctionField
          source="phone"
          render={({ phone }) => formatPhone(phone)}
        />
        <ChipField color="secondary" source="roleName" />
      </Datagrid>
    </List>
  );
};

export default TenantAdminsList;
