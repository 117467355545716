/* eslint-disable prefer-template */
import { defaultTheme } from 'react-admin';

export const P = {
  primary: '#0F9999',
  secondary: '#283593',
  background: '#F5F5F9',
};

const softLightTheme = {
  palette: {
    primary: {
      main: P.primary,
    },
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff',
    },
    warning: {
      main: 'rgb(255,77,79)',
    },
    background: {
      default: P.background,
    },
    mode: 'light',
  },
  shape: {
    borderRadius: 10,
  },
  sidebar: {
    width: 250,
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          padding: '8px 12px',
          margin: '0px 3px 4px',
          fontWeight: '500',
          fontSize: 13,
          borderRadius: 10,
          borderTop: '1px solid transparent',
          borderBottom: '1px solid transparent',
          '&.RaMenuItemLink-active': {
            color: P.primary,
            fontWeight: 600,
            borderRight: `5px solid ${P.primary}`,
            background: `${P.primary}15`,
            borderRadius: 10,
            '& .MuiSvgIcon-root': {
              fill: P.primary,
            },
          },
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          padding: '0px 6px',
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          '& .RaLayout-appFrame': { marginTop: 54 },
          '& .RaLayout-content': { paddingTop: 24, marginLeft: 2 },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'dense',
        size: 'small',
      },
      styleOverrides: {
        root: {
          margin: '5px 0px 5px 0px',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        margin: 'dense',
        size: 'small',
      },
      styleOverrides: {
        root: {
          margin: '5px 0px 1px 0px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        outlined: {
          fontWeight: 600,
        },
        sizeSmall: {
          padding: '10px 18px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          border: '1px solid #e0e0e3',
          backgroundClip: 'padding-box',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          border: 'none',
          padding: 3,
        },
        colorSecondary: {
          color: '#222',
          backgroundColor: '#fff',
        },
      },
      defaultProps: {
        elevation: 1,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&.MuiTableCell-sizeSmall': {
            padding: '8px 10px',
          },
          '&.MuiTableCell-paddingNone': {
            padding: 0,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            fontWeight: 600,
            fontSize: 13,
            backgroundColor: '#fafafa',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          height: 'calc(100% - 54px)',
          padding: '0px !important',
          border: 'none',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          padding: 12,
          '&:last-child td': { border: 0 },
        },
        hover: {
          '&:hover': {
            backgroundColor: '#fafafa',
          },
        },
      },
    },
  },
};

export default softLightTheme;
